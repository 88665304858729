
<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white pl-3">
    <a class="navbar-brand pr-5" href="#"
      ><img
        width="100"
        src="https://cv-screen.s3.ap-southeast-2.amazonaws.com/app/logo.png"
    /></a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" role="button"
            ><i class="fas fa-bars"></i
          ></a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
          <router-link class="nav-link" to="/">Home</router-link>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
          <a
            href="//cvscreen.com.au/contact-us/"
            target="_blank"
            class="nav-link"
            >Contact</a
          >
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "SideBarAdmin",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions(["fetchSettingAll"]),
  },
  created() {
    this.fetchSettingAll();
  },
};
</script>
import * as messageService from "../../services/message.service";
import * as productCategoryService from '../../services/productCategory.service';


const state = {
    productCategoryList:[],
};

const getters = {
    getProductCategoryList:state => (state.productCategoryList),
};

const actions = {
    async fetchProductCategoryList({ commit }){
        try {
            const response = await productCategoryService.list();
            commit('setProductCategoryList', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
};

const mutations = {

    setProductCategoryList:(state, res) => (
        state.productCategoryList =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}

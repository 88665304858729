import { http} from './http_service';


// Get list for table
export function list(noteId){
    return http().get(`/notes/list/${noteId}`);
}

// start new note
export function add(checkId, productId, data){
    return http().post(`/notes/add/${checkId}/${productId}`, data)
}
// Update notes
export function update(noteId, index, data){
    return http().put(`/notes/update/${noteId}/${index}`, data)
}


import * as projectService from '../../services/project.service';

const state = {
    projectsListPaginateCurrent:{},
    projectsListPaginate:{},
    projectsDashboardData:[],
    projectsListByUser:[],
    projectsSingle:{},

};

const getters = {
    getProjectsListPaginate:state => (state.projectsListPaginate),
    getProjectsListPaginateCurrent:state => (state.projectsListPaginateCurrent),
    getProjectsDashboardData:state => (state.projectsDashboardData),
    getProjectsListByUser:state => (state.projectsListByUser),
    getProjectsSingle:state => (state.projectsSingle),
};

const actions = {
      // Get list with pagination
      async fetchProjectsListPaginate({ commit}, obj){
        try {

          console.log(obj);
            const response = await projectService.listPaginate(obj);
            commit('setProjectsListPaginateCurrent', obj);
            commit('setProjectsListPaginate', response.data);
        } catch (error) {
            console.log('fetchProjectsListPaginate', error);
        }
    },


    async fetchProjectsDashboardData({ commit }){
        try {

            let totalProjects = 0;
            let obj = {}
            let arrMain = [];
            let totalDraft = 0;
            let totalNew = 0;
            let totalMIR = 0;
            let totalWFR = 0;
            let totalCompleted = 0;
            let totalCanceled = 0;

            const response = await projectService.list();
            arrMain = response.data.data;

            for (let i = 0; i < arrMain.length; i++) {
                let arrChild=[];

                arrChild = arrMain[i].products;

                /**
                 * Get total more information require projects
                 */
                totalMIR   =  totalMIR  + checkSomeStatusAreSameProjects(arrChild, 3)

                /**
                 * Get total Waiting For Police Review projects
                 */
                totalWFR = totalWFR + checkSomeStatusAreSameProjects(arrChild, 4)

                /**
                 * Get total new projects
                 */
                totalNew = totalNew + checkEveryStatusAreSameProjects(arrChild, null)

                /**
                 * Get total completed projects
                 */
                totalCompleted = totalCompleted + checkEveryStatusAreSameProjects(arrChild, 5)

                /**
                 * Get total canceled projects
                 */
                totalCanceled = totalCanceled + checkEveryStatusAreSameProjects(arrChild, 6)

            }

            /**
             * Total projects
             */
            totalProjects = response.data.data.length;

            obj=  {
                "totalDraft":totalDraft,
                "totalNew":totalNew,
                "totalMIR":totalMIR,
                "totalWFR":totalWFR,
                "totalCompleted":totalCompleted,
                "totalCanceled":totalCanceled,
                "total": Number(totalProjects)
            };

            commit('setProjectsDashboardData', obj);
        } catch (error) {
            console.log('fetchProjectsDashboardData - something wrong', error)
        }
    },
    async fetchProjectsListByUser({ commit }, userId){
        try {
            const response = await projectService.projectsByUser(userId);
            commit('setProjectsListByUser', response.data);
        } catch (error) {
            console.log('fetchProjectsListByUser - something wrong', error)
        }
    },
    async fetchProjectsSingle({ commit }, projectId){
      try {
          const response = await projectService.single(projectId);
          commit('setProjectsSingle', response.data);
      } catch (error) {
          console.log('fetchProjectsSingle - something wrong', error)
      }
  },

};

const mutations = {
  setProjectsListPaginateCurrent:(state, res) => (
    state.projectsListPaginateCurrent =  res
  ),
  setProjectsListPaginate:(state, res) => (
    state.projectsListPaginate =  res
  ),
  setProjectsDashboardData:(state, res) => (
      state.projectsDashboardData =  res
  ),
  setProjectsListByUser:(state, res) => (
      state.projectsListByUser =  res
  ),
  setProjectsSingle:(state, res) => (
    state.projectsSingle =  res
  ),
};


export default{
    state,
    getters,
    actions,
    mutations,
}

/**
 * Check every check has in same status within a project
 * @param array arrChild
 * @returns 1 or 0
 */
function checkEveryStatusAreSameProjects(arrChild, statusValue){
    let res = false;
    res = arrChild.every(item => item.status === statusValue)

    if(res === true){
        return 1
    }else{
        return 0
    }
}

/**
 * Check some check has in same status within a project
 * @param array arrChild
 * @returns 1 or 0
 */
function checkSomeStatusAreSameProjects(arrChild, statusValue){
    let res = false;
    res = arrChild.some(item => item.status === statusValue)

    if(res === true){
        return 1
    }else{
        return 0
    }
}

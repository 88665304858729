import { http, httpFile } from './http_service';

// Get products List
export function list(){
    return http().get(`/products/list`);
}

// Get products according to category Id
export function listAccordingToCategory(categoryId){
    return http().get(`/products/list-according-to-category/${categoryId}`);
}

// Add a new Product
export function add(data){
    return http().post('/products/add', data)
}
// Update a Product
export function update(id, data){
    return http().put(`/products/update/${id}`, data)
}

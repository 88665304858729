import { http, httpFile } from './http_service';

// Get all
export function policeChecksAll(userId){
    return http().get(`/users-individual/police-checks/all/${userId}`);
}
export function all(){
    return http().get('/users-individual/all');
}
export function add(data){
    return http().post('/users-individual/add', data)
}
export function update(id, data){
    return http().post(`/users-individual/update/${id}`, data)
}

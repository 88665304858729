import * as messageService from "../../services/message.service";
import * as documentTypeService from '../../services/documentType.service';


const state = {
    documentTypeAll:[],
    documentTypeAllActive:[],
    documentCategoryDropdown:[],


};

const getters = {
    gettDocumentTypeAll:state => (state.documentTypeAll),
    gettDocumentTypeAllActive:state => (state.documentTypeAllActive),
    gettDocumentCategoryDropdown:state => (state.documentCategoryDropdown),

};

const actions = {
    async fetchDocumentTypeAll({ commit } ){
        try {
            const response = await documentTypeService.all();

            console.log('response', response);

            commit('setDocumentTypeAll', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
    async fetchDocumentTypeAllActive({ commit } ){
        try {
            const response = await documentTypeService.allActive();
            commit('setDocumentTypeAllActive', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
    async fetchDocumentCategoryDropdown({ commit }){
        try {
            const response = await documentTypeService.dropdown();

            console.log('response', response);

            commit('setDocumentCategoryDropdown', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
};

const mutations = {
    setDocumentTypeAll:(state, res) => (
        state.documentTypeAll =  res
    ),
    setDocumentTypeAllActive:(state, res) => (
        state.documentTypeAllActive =  res
    ),
    setDocumentCategoryDropdown:(state, res) => (
        state.documentCategoryDropdown =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}


import * as chkRightWorkAustraliaService from '../../services/chkRightWorkAustralia.service';

const state = {
    rightWorkAustraliaListPaginate:{},
    rightWorkAustraliaListPaginateCurrent:{},
    rightWorkAustraliaSingle:{},
    rightWorkAustraliaDashboardData:{},
};

const getters = {
    getRightWorkAustraliaListPaginate:state => (state.rightWorkAustraliaListPaginate),
    getRightWorkAustraliaListPaginateCurrent:state => (state.rightWorkAustraliaListPaginateCurrent),
    getRightWorkAustralia:state => (state.rightWorkAustraliaSingle),
    getRightWorkAustraliaDashboardData:state => (state.rightWorkAustraliaDashboardData),
};

const actions = {
    // Get list with pagination
    async fetchRightWorkAustraliaListPaginate({ commit}, obj){
        try {
            const response = await chkRightWorkAustraliaService.listPaginate(obj);
            commit('setRightWorkAustraliaListPaginateCurrent', obj);
            commit('setRightWorkAustraliaListPaginate', response.data);
        } catch (error) {
            console.log('fetchRightWorkAustraliaListPaginate', error);
        }
    },

    async fetchRightWorkAustralia({ commit }, projectId){
        try {
            const response = await chkRightWorkAustraliaService.single(projectId);

            commit('setRightWorkAustralia', response.data);
        } catch (error) {
            console.log('fetchRightWorkAustralia - something wrong', error)
        }
    },

    async fetchRightWorkAustraliaById({ commit }, id){
      try {
          const response = await chkRightWorkAustraliaService.singleById(id);

          commit('setRightWorkAustralia', response.data);
      } catch (error) {
          console.log('fetchRightWorkAustraliaById - something wrong', error)
      }
    },

    // Get dashboard data
    async fetchRightWorkAustraliaDashboardData({ commit}){
        try {
            const response = await chkRightWorkAustraliaService.dashboardDataProject();
            let totalDraft = 0;
            let totalNew = 0;
            let totalMIR = 0;
            let totalWFR = 0;
            let totalCompleted = 0;
            let totalCanceled = 0;

            const arr = response.data;
            let obj={}

            for (var i = 0, len = arr.length; i < len; i++) {
                // Draft
                if(arr[i].status == "1"){
                    totalDraft = Number(totalDraft) + 1
                }

                // New
                if(arr[i].status == "2"){
                    totalNew = Number(totalNew) + 1
                }

                // MIR
                if(arr[i].status == "3"){
                    totalMIR = Number(totalMIR) + 1
                }

                // WFR
                if(arr[i].status == 4){
                    totalWFR = Number(totalWFR) + 1
                }

                // Completed
                if(arr[i].status == 5){
                    totalCompleted = Number(totalCompleted) + 1
                }

                // Canceled
                if(arr[i].status == 6){
                    totalCanceled = Number(totalCanceled) + 1
                }
            }


            obj=  {
                "totalDraft":totalDraft,
                "totalNew":totalNew,
                "totalMIR":totalMIR,
                "totalWFR":totalWFR,
                "totalCompleted":totalCompleted,
                "totalCanceled":totalCanceled,
                "total": Number(totalNew) + Number(totalMIR) + Number(totalWFR) + Number(totalCompleted)
            };

            commit('setRightWorkAustraliaDashboardData', obj)

        } catch (error) {
            console.log('fetchRightWorkAustraliaDashboardData', error);

        }
    },

};

const mutations = {
    setRightWorkAustraliaListPaginateCurrent:(state, res) => (
        state.rightWorkAustraliaListPaginateCurrent =  res
    ),
    setRightWorkAustraliaListPaginate:(state, res) => (
        state.rightWorkAustraliaListPaginate =  res
    ),
    setRightWorkAustralia:(state, res) => (
        state.rightWorkAustraliaSingle =  res
    ),
    setRightWorkAustraliaDashboardData:(state, res) => (
        state.rightWorkAustraliaDashboardData =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}

import { http, httpFile } from './http_service';


export function all(){
    return http().get('/users-admin/all');
}
export function single(userId){
    return http().get(`/users-admin/single/${userId}`)
}
export function add(data){
    return http().post('/users-admin/add', data)
}
export function update(id, data){
    return http().put(`/users-admin/update/${id}`, data)
}
// Get user list according to category id
export function byCategory(categoryId){
    return httpFile().get(`/users-admin/by-category/${categoryId}`)
}
// Get list with Pagination
export function listPaginate(currentPage, paginate, roleIds, parentIds, search, selectedStatus, fromDate, toDate, sortDirection, sortField){
    return http().get(`/users-admin/list-paginate?page=${currentPage}&paginate=${paginate}&role_ids=${roleIds}&parent_id=${parentIds}&q=${search}&selected_status=${selectedStatus}&from_date=${fromDate}&to_date=${toDate}&sort_direction=${sortDirection}&sort_field=${sortField}`);
}
export function listExport(roleIds, parentId, search, selectedStatus, fromDate, toDate, sortDirection, sortField){
    return http().get(`/users-admin/list-export?role_ids=${roleIds}&parent_id=${parentId}&q=${search}&selected_status=${selectedStatus}&from_date=${fromDate}&to_date=${toDate}&sort_direction=${sortDirection}&sort_field=${sortField}`);
}

// Get Stakeholder settings via parent_id
export function getStakeholderSettings(parentId){
    return http().get(`/users-admin/stakeholder-settings/${parentId}`);
}



import * as referenceService from '../../services/chkReference.service';

const state = {
    referenceListPaginate:{},
    referenceListPaginateCurrent:{},
    referenceSingle:{},
    referenceDashboardData:{},
};

const getters = {
    getReferenceListPaginate:state => (state.referenceListPaginate),
    getReferenceListPaginateCurrent:state => (state.referenceListPaginateCurrent),
    getReference:state => (state.referenceSingle),
    getReferenceDashboardData:state => (state.referenceDashboardData),
};

const actions = {
     // Get list with pagination
     async fetchReferenceListPaginate({ commit}, obj){
        try {
            const response = await referenceService.listPaginate(obj);
            commit('setReferenceListPaginateCurrent', obj);
            commit('setReferenceListPaginate', response.data);
        } catch (error) {
            console.log('fetchReferenceListPaginate', error);
        }
    },

    /**
     *
     * @param {*} param0
     * @param {*} obj : projectId and productId
     *
     */
    async fetchReference({ commit }, obj){
        try {
            const response = await referenceService.single(obj);

            commit('setReference', response.data);
        } catch (error) {
            console.log('fetchReference - something wrong', error)
        }
    },

    /**
     *
     * @param {*} param0
     * @param {*} id : Check id
     *
     */
    async fetchReferenceById({ commit }, id){
      try {
          const response = await referenceService.singleById(id);
          commit('setReference', response.data);
      } catch (error) {
          console.log('fetchReferenceById - something wrong', error)
      }
    },

    // Get dashboard data
    async fetchReferenceDashboardData({ commit}){
        try {
            const response = await referenceService.dashboardDataProject();

            let totalDraft = 0;
            let totalNew = 0;
            let totalMIR = 0;
            let totalWFR = 0;
            let totalCompleted = 0;
            let totalCanceled = 0;

            const arr = response.data;
            let obj={}

            for (var i = 0, len = arr.length; i < len; i++) {
                // Draft
                if(arr[i].status == "1"){
                    totalDraft = Number(totalDraft) + 1
                }

                // New
                if(arr[i].status == "2"){
                    totalNew = Number(totalNew) + 1
                }

                // MIR
                if(arr[i].status == "3"){
                    totalMIR = Number(totalMIR) + 1
                }

                // WFR
                if(arr[i].status == 4){
                    totalWFR = Number(totalWFR) + 1
                }

                // Completed
                if(arr[i].status == 5){
                    totalCompleted = Number(totalCompleted) + 1
                }

                // Canceled
                if(arr[i].status == 6){
                    totalCanceled = Number(totalCanceled) + 1
                }
            }

            obj=  {
                "totalDraft":totalDraft,
                "totalNew":totalNew,
                "totalMIR":totalMIR,
                "totalWFR":totalWFR,
                "totalCompleted":totalCompleted,
                "totalCanceled":totalCanceled,
                "total": Number(totalNew) + Number(totalMIR) + Number(totalWFR) + Number(totalCompleted)
            };

            commit('setReferenceDashboardData', obj)

        } catch (error) {
            console.log('fetchReferenceDashboardData', error);

        }
    },
};

const mutations = {

    setReferenceListPaginateCurrent:(state, res) => (
        state.referenceListPaginateCurrent =  res
    ),
    setReferenceListPaginate:(state, res) => (
        state.referenceListPaginate =  res
    ),
    setReference:(state, res) => (
        state.referenceSingle =  res
    ),
    setReferenceDashboardData:(state, res) => (
        state.referenceDashboardData =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}


import * as productService from '../../services/product.service';

const state = {
    productList:[],
    productListAccordingToCategory:[],
};

const getters = {
    getProductList:state => (state.productList),
    getProductListAccordingToCategory:state => (state.productListAccordingToCategory),
};

const actions = {
    async fetchProductList({ commit }){
        try {
            const response = await productService.list();

            commit('setProductList', response.data);
        } catch (error) {
            console.log('fetchProductList - something wrong', error)
        }
    },
    async fetchProductListAccordingToCategory({ commit }, categoryId){
        try {
            const response = await productService.listAccordingToCategory(categoryId);

            commit('setProductListAccordingToCategory', response.data);
        } catch (error) {
            console.log('fetchProductList - something wrong', error);
        }
    },
};

const mutations = {
    
    setProductList:(state, res) => (
        state.productList =  res
    ),
    setProductListAccordingToCategory:(state, res) => (
        state.productListAccordingToCategory =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}
import Vue from 'vue';
import VueRouter from 'vue-router';
import homeAdmin from '../views/admin/Home.vue';
import homeIU from '../views/individual/Home.vue';
import homeCM from '../views/corporate/Home.vue';
import homeCC from '../views/corporateCustomer/Home.vue';
import homeCO from '../views/common/Home.vue';
import homePartner from '../views/partner/Home.vue';
import * as auth from '../services/auth.service';


Vue.use(VueRouter)

const routes = [
  {
    path:'/admin',
    name:'admin',
    component:homeAdmin,
    children:[
        {
            path: '',
            name: 'dashboardAdmin',
            component:() => import('../views/admin/Dashboard.vue'),
        },
        {
          path: 'users',
          name: 'usersListAdmin',
          component:() => import('../views/admin/UsersList.vue'),
        },
        {
          path: 'police-check-list',
          name: 'policeCheckListAdmin',
          component:() => import('../views/admin/PoliceCheckList.vue'),
        },
        {
          path: 'project-list',
          name: 'projectListAdmin',
          component:() => import('../views/admin/ProjectList.vue'),
        },

        {
          path: 'check-right-to-work-in-australia-list',
          name: 'checkRightToWorkInAustraliaAdmin',
          component:() => import('../views/checkRightToWorkInAustralia/RightToWorkInAustraliaList.vue'),
        },
        {
          path: 'check-academic-qualification-list',
          name: 'checkAcademicQualificationListAdmin',
          component:() => import('../views/checkAcademicQualification/CheckAcademicQualificationList.vue'),
        },
        {
          path: 'check-professional-membership-list',
          name: 'checkProfessionalMembershipListAdmin',
          component:() => import('../views/checkProfessionalMembership/CheckProfessionalMembershipList.vue'),
        },
        {
          path: 'check-employment-history-list',
          name: 'checkEmploymentHistoryListAdmin',
          component:() => import('../views/checkEmploymentHistory/CheckEmploymentHistoryList.vue'),
        },
        {
          path: 'check-bankruptcy-list',
          name: 'checkBankruptcyListAdmin',
          component:() => import('../views/checkBankruptcy/CheckBankruptcyList.vue'),
        },
        {
          path: 'check-drivers-licence-traffic-offence-list',
          name: 'checkDriversLicenceTrafficOffenceListAdmin',
          component:() => import('../views/checkDriversLicenceTrafficOffence/CheckDriversLicenceTrafficOffenceList.vue'),
        },
        {
          path: 'check-character-reference-list',
          name: 'checkCharacterReferenceList',
          component:() => import('../views/checkReference/CheckReferenceList.vue'),
        },
        {
          path: 'profile',
          name: 'profileAdmin',
          component:() => import('../views/admin/Profile.vue'),
        },
        {
          path: 'password-change',
          name: 'passwordChangeAdmin',
          component:() => import('../views/admin/PasswordChange.vue'),
        },
        {
          path: 'payment',
          name: 'paymentAdmin',
          component:() => import('../views/Payment.vue'),
        },
        {
          path: 'steps',
          name: 'stepsAdmin',
          component:() => import('../views/policeCheck/steps.vue'),
        },


        {
          path: 'reports/police-check-summary',
          name: 'reportsPoliceCheckSummaryAdmin',
          component:() => import('../views/reports/PoliceCheckSummary.vue'),
        },

        // Views
        // Right To Work In Australia Check View
        {
          path: 'check-right-to-work-in-australia-view/:projectId/:userId/:checkId',
          name: 'checkRightToWorkInAustraliaViewAdmin',
          component:() => import('../views/checkRightToWorkInAustralia/RightToWorkInAustraliaView.vue'),
        },

        // Academic Qualification Check View
        {
          path: 'check-academic-qualification-view/:projectId/:userId/:checkId',
          name: 'checkAcademicQualificationViewAdmin',
          component:() => import('../views/checkAcademicQualification/CheckAcademicQualificationView.vue'),
        },

        // Professional Membership Check View
        {
          path: 'check-professional-membership-view/:projectId/:userId/:checkId',
          name: 'checkProfessionalMembershipViewAdmin',
          component:() => import('../views/checkProfessionalMembership/CheckProfessionalMembershipView.vue'),
        },

        // Employment History Check View
        {
          path: 'check-employment-history-view/:projectId/:userId/:checkId',
          name: 'checkEmploymentHistoryViewAdmin',
          component:() => import('../views/checkEmploymentHistory/CheckEmploymentHistoryView.vue'),
        },

        // Bankruptcy Record Check View
        {
          path: 'check-bankruptcy-record-view/:projectId/:userId/:checkId',
          name: 'checkBankruptcyRecordViewAdmin',
          component:() => import('../views/checkBankruptcy/CheckBankruptcyView.vue'),
        },

        // Drivers Licence and Traffic Offence Check View
        {
          path: 'check-drivers-licence-traffic-offence-view/:projectId/:userId/:checkId',
          name: 'checkDriversLicenceTrafficOffenceViewAdmin',
          component:() => import('../views/checkDriversLicenceTrafficOffence/CheckDriversLicenceTrafficOffenceView.vue'),
        },



        {
          path: 'settings/document-type',
          name: 'settingsDocumentTypeAdmin',
          component:() => import('../views/settings/DocumentTypeList.vue'),
        },
        {
          path: 'settings/product-list',
          name: 'settingsProductListAdmin',
          component:() => import('../views/settings/ProductList.vue'),
        },
        {
          path: 'settings/system-settings',
          name: 'settingsSystemSettingsAdmin',
          component:() => import('../views/settings/SystemSettings.vue'),
        },
        {
          path: 'settings/site-maintenance',
          name: 'settingsSystemSiteMaintenance',
          component:() => import('../views/settings/SiteMaintenance.vue'),

        },

        /**
         * Delete after fix aws bucket issue
         */
        {
          path: 'temp-aws-s3-bucket-fix',
          name: 'tempAWSS3BucketFix',
          component:() => import('../views/admin/TempAWSS3BucketFix.vue'),
        },
    ],
    beforeEnter(to, from, next){
      if(!auth.isLoggedIn()){
          next('/login');
      }else{
          const userObj = auth.getUserFromToken();
          const  roles  = [1,2];

          if (roles.includes(Number(userObj.role_id))) {
            next()
          }else{
            next('/login');
          }
      }
    }
  },

  // Individual user
  {
    path:'/individual-user',
    name:'individualUser',
    component:homeIU,
    children:[
        {
            path: '',
            name: 'dashboardIU',
            component:() => import('../views/individual/Dashboard.vue'),
        },
        {
          path: 'create-police-check',
          name: 'createPoliceCheckIU',
          component:() => import('../views/policeCheck/steps.vue'),
        },
        {
          path: 'update-police-check/:userId/:id',
          name: 'updatePoliceCheckIU',
          component:() => import('../views/policeCheck/steps.vue'),
        },
        {
          path: 'police-check-steps-new',
          name: 'PoliceCheckIUStepsNew',
          component:() => import('../views/policeCheck/PoliceCheckStepsNew.vue'),
        },
        {
          path: 'police-check-steps-edit/:userId/:id',
          name: 'PoliceCheckIUStepsEdit',
          component:() => import('../views/policeCheck/PoliceCheckStepsEdit.vue'),
        },
        {
          path: 'police-check-view/:id',
          name: 'PoliceCheckView',
          component:() => import('../views/policeCheck/PoliceCheckView.vue'),
        },
        {
          path: 'police-check-submitted-message',
          name: 'policeCheckSubmittedMessage',
          component:() => import('../views/policeCheck/PoliceCheckSubmitedMessage.vue'),
        },

        // Successful Submission Msg
        {
          path: 'check-successful-submission-msg/:productId',
          name: 'checkSuccessfulSubmissionMsgIC',
          component:() => import('../views/individual/SuccessfulSubmissionMsg.vue'),
        },


        {
          path: 'users',
          name: 'usersAdmin',
          component:() => import('../views/admin/UsersList.vue'),
        },
        {
          path: 'profile',
          name: 'profileAdmin',
          component:() => import('../views/admin/Profile.vue'),
        },
        {
          path: 'password-change',
          name: 'passwordChangeAdmin',
          component:() => import('../views/admin/PasswordChange.vue'),
        },
    ],
    beforeEnter(to, from, next){
      if(!auth.isLoggedIn()){
          next('/login');
      }else{
        const userObj = auth.getUserFromToken();
        const  roles  = [3,8, 9];

        if (roles.includes(Number(userObj.role_id))) {
          next()
        }else{
          next('/login');
        }
      }
    }
  },

  // Corporate-manager
  {
    path:'/corporate-manager',
    name:'corporateManager',
    component:homeCM,
    children:[
        {
            path: '',
            name: 'dashboardCM',
            component:() => import('../views/corporate/Dashboard.vue'),
        },
        {

          path: 'user-list',
          name: 'userListCM',
          component:() => import('../views/corporate/UserList.vue'),
        },
        {
          path: 'customer-list',
          name: 'customerListCM',
          component:() => import('../views/corporate/CustomerList.vue'),
        },


        {
          path: 'product-list',
          name: 'productListCM',
          component:() => import('../views/corporate/ProjectNew.vue'),
        },

        // Projects
        {
          path: 'project-list',
          name: 'projectListCM',
          component:() => import('../views/corporate/ProjectList.vue'),
        },
        {
          path: 'project-new/:user_id',
          name: 'projectNewCM',
          component:() => import('../views/corporate/ProjectNew.vue'),
        },

        // Views
        // Right To Work In Australia Check View
         {
          path: 'check-right-to-work-in-australia-view/:projectId/:userId/:checkId',
          name: 'checkRightToWorkInAustraliaViewCM',
          component:() => import('../views/checkRightToWorkInAustralia/RightToWorkInAustraliaView.vue'),
        },

        // Academic Qualification Check View
        {
          path: 'check-academic-qualification-view/:projectId/:userId/:checkId',
          name: 'checkAcademicQualificationViewCM',
          component:() => import('../views/checkAcademicQualification/CheckAcademicQualificationView.vue'),
        },

        // Professional Membership Check View
        {
          path: 'check-professional-membership-view/:projectId/:userId/:checkId',
          name: 'checkProfessionalMembershipViewCM',
          component:() => import('../views/checkProfessionalMembership/CheckProfessionalMembershipView.vue'),
        },

        // Employment History Check View
        {
          path: 'check-employment-history-view/:projectId/:userId/:checkId',
          name: 'checkEmploymentHistoryViewCM',
          component:() => import('../views/checkEmploymentHistory/CheckEmploymentHistoryView.vue'),
        },

        // Bankruptcy Record Check View
        {
          path: 'check-bankruptcy-record-view/:projectId/:userId/:checkId',
          name: 'checkBankruptcyRecordViewCM',
          component:() => import('../views/checkBankruptcy/CheckBankruptcyView.vue'),
        },

        // Drivers Licence and Traffic Offence Check View
        {
          path: 'check-drivers-licence-traffic-offence-view/:projectId/:userId/:checkId',
          name: 'checkDriversLicenceTrafficOffenceViewCM',
          component:() => import('../views/checkDriversLicenceTrafficOffence/CheckDriversLicenceTrafficOffenceView.vue'),
        },
        // Successful Submission Msg corporate manager
        {
          path: 'check-successful-submission-msg/:productId',
          name: 'checkSuccessfulSubmissionMsgCM',
          component:() => import('../views/corporateCustomer/SuccessfulSubmissionMsg.vue'),
        },


        {
          path: 'profile',
          name: 'profileAdmin',
          component:() => import('../views/admin/Profile.vue'),
        },
        {
          path: 'password-change',
          name: 'passwordChangeAdmin',
          component:() => import('../views/admin/PasswordChange.vue'),
        },
    ],
    beforeEnter(to, from, next){
      if(!auth.isLoggedIn()){
          next('/login');
      }else{
        const userObj = auth.getUserFromToken();
        const  roles  = [4,5];

        if (roles.includes(Number(userObj.role_id))) {
          next()
        }else{
          next('/login');
        }
      }
    }
  },

  // Corporate-customer
  {
    path:'/corporate-customer',
    name:'corporateCustomer',
    component:homeCC,
    children:[
        {
            path: '',
            name: 'dashboardCC',
            component:() => import('../views/corporateCustomer/Dashboard.vue'),
        },


        // Right To Work In Australia Check
        {
          path: 'check-right-to-work-in-australia/:projectId/:userId/:checkId',
          name: 'checkRightToWorkInAustraliaIU',
          component:() => import('../views/checkRightToWorkInAustralia/RightToWorkInAustralia.vue'),
        },
        {
          path: 'check-right-to-work-in-australia-certificate/:projectId/:userId/:checkId',
          name: 'checkRightToWorkInAustraliaCertificateCC',
          component:() => import('../views/checkRightToWorkInAustralia/RightToWorkInAustraliaCertificate.vue'),
        },

        // Academic Qualification Check
        {
          path: 'check-academic-qualification/:projectId/:userId/:checkId',
          name: 'checkAcademicQualificationIU',
          component:() => import('../views/checkAcademicQualification/CheckAcademicQualification.vue'),
        },
        {
          path: 'check-academic-qualification-certificate/:projectId/:userId/:checkId',
          name: 'checkAcademicQualificationCertificateCC',
          component:() => import('../views/checkAcademicQualification/CheckAcademicQualificationCertificate.vue'),
        },

        // Professional Membership Check
        {
          path: 'check-professional-membership/:projectId/:userId/:checkId',
          name: 'checkProfessionalMembershipIU',
          component:() => import('../views/checkProfessionalMembership/CheckProfessionalMembership.vue'),
        },
        {
          path: 'check-professional-membership-certificate/:projectId/:userId/:checkId',
          name: 'checkProfessionalMembershipCertificateCC',
          component:() => import('../views/checkProfessionalMembership/CheckProfessionalMembershipCertificate.vue'),
        },

        // Employment History Check
        {
          path: 'check-employment-history/:projectId/:userId/:checkId',
          name: 'checkEmploymentHistoryIU',
          component:() => import('../views/checkEmploymentHistory/CheckEmploymentHistory.vue'),
        },
        {
          path: 'check-employment-history-certificate/:projectId/:userId/:checkId',
          name: 'checkEmploymentHistoryCertificateCC',
          component:() => import('../views/checkEmploymentHistory/CheckEmploymentHistoryCertificate.vue'),
        },

        // Bankruptcy Record Check
        {
          path: 'check-bankruptcy-record/:projectId/:userId/:checkId',
          name: 'checkBankruptcyRecordIU',
          component:() => import('../views/checkBankruptcy/CheckBankruptcy.vue'),
        },
        {
          path: 'check-bankruptcy-record-certificate/:projectId/:userId/:checkId',
          name: 'checkBankruptcyRecordCertificateCC',
          component:() => import('../views/checkBankruptcy/CheckBankruptcyCertificate.vue'),
        },


        // Drivers Licence and Traffic Offence Chec
        {
          path: 'check-drivers-licence-traffic-offence/:projectId/:userId/:checkId',
          name: 'checkDriversLicenceTrafficOffenceIU',
          component:() => import('../views/checkDriversLicenceTrafficOffence/CheckDriversLicenceTrafficOffence.vue'),
        },
        {
          path: 'check-drivers-licence-traffic-offence-certificate/:projectId/:userId/:checkId',
          name: 'checkDriversLicenceTrafficOffenceCertificateCC',
          component:() => import('../views/checkDriversLicenceTrafficOffence/CheckDriversLicenceTrafficOffenceCertificate.vue'),
        },





        {
          path: 'police-check-view/:id',
          name: 'PoliceCheckViewCC',
          component:() => import('../views/policeCheck/PoliceCheckView.vue'),
        },
        {
          path: 'check-police-certificate/:userId/:checkId',
          name: 'checkPoliceCertificateCC',
          component:() =>import('../views/policeCheck/CheckPoliceCertificate.vue'),
        },


        // Right To Work In Australia Check View
        {
          path: 'check-right-to-work-in-australia-view/:projectId/:userId/:checkId',
          name: 'checkRightToWorkInAustraliaViewCC',
          component:() => import('../views/checkRightToWorkInAustralia/RightToWorkInAustraliaView.vue'),
        },

        // Academic Qualification Check View
        {
          path: 'check-academic-qualification-view/:projectId/:userId/:checkId',
          name: 'checkAcademicQualificationViewCC',
          component:() => import('../views/checkAcademicQualification/CheckAcademicQualificationView.vue'),
        },

        // Professional Membership Check View
        {
          path: 'check-professional-membership-view/:projectId/:userId/:checkId',
          name: 'checkProfessionalMembershipViewCC',
          component:() => import('../views/checkProfessionalMembership/CheckProfessionalMembershipView.vue'),
        },

        // Employment History Check View
        {
          path: 'check-employment-history-view/:projectId/:userId/:checkId',
          name: 'checkEmploymentHistoryViewCC',
          component:() => import('../views/checkEmploymentHistory/CheckEmploymentHistoryView.vue'),
        },

        // Bankruptcy Record Check View
        {
          path: 'check-bankruptcy-record-view/:projectId/:userId/:checkId',
          name: 'checkBankruptcyRecordViewCC',
          component:() => import('../views/checkBankruptcy/CheckBankruptcyView.vue'),
        },

        // Drivers Licence and Traffic Offence Check View
        {
          path: 'check-drivers-licence-traffic-offence-view/:projectId/:userId/:checkId',
          name: 'checkDriversLicenceTrafficOffenceViewCC',
          component:() => import('../views/checkDriversLicenceTrafficOffence/CheckDriversLicenceTrafficOffenceView.vue'),
        },

        // Successful Submission Msg corporate customer
        {
          path: 'check-successful-submission-msg/:productId',
          name: 'checkSuccessfulSubmissionMsgCC',
          component:() => import('../views/corporateCustomer/SuccessfulSubmissionMsg.vue'),
        },















        {
          path: 'create-police-check',
          name: 'createPoliceCheckCC',
          component:() => import('../views/policeCheck/steps.vue'),
        },
        {
          path: 'update-police-check/:userId/:id',
          name: 'updatePoliceCheckCC',
          component:() => import('../views/policeCheck/steps.vue'),
        },
        {
          path: 'police-check-steps-new',
          name: 'PoliceCheckCCStepsNew',
          component:() => import('../views/policeCheck/PoliceCheckStepsNew.vue'),
        },
        {
          path: 'police-check-steps-edit/:userId/:id',
          name: 'PoliceCheckCCStepsEdit',
          component:() => import('../views/policeCheck/PoliceCheckStepsEdit.vue'),
        },

        // {
        //   path: 'police-check-submitted-message',
        //   name: 'policeCheckSubmittedMessage',
        //   component:() => import('../views/policeCheck/PoliceCheckSubmitedMessage.vue'),
        // },


        {
          path: 'check-police-step1-cu/:id/:shId/:projectId/:parentId/:customerId/:checkId/:mode',
          name: 'checkPoliceStep1CU',
          component:() =>import('../views/policeCheck/CheckPoliceStep1CU.vue'),
        },
        {
          path: 'check-police-step2/:id/:shId/:projectId/:parentId/:customerId/:checkId/:mode',
          name: 'checkPoliceStep2CU',
          component:() =>import('../views/policeCheck/CheckPoliceStep2.vue'),
        },
        {
          path: 'check-police-step3/:id/:shId/:projectId/:parentId/:customerId/:checkId/:mode',
          name: 'checkPoliceStep3CU',
          component:() =>import('../views/policeCheck/CheckPoliceStep3.vue'),
        },

        {
          path: 'check-police-step4/:id/:shId/:projectId/:parentId/:customerId/:checkId/:mode',
          name: 'checkPoliceStep4CU',
          component:() =>import('../views/policeCheck/CheckPoliceStep4.vue'),
        },
        {
          path: 'check-police-step5/:id/:shId/:projectId/:parentId/:customerId/:checkId/:mode',
          name: 'checkPoliceStep5CU',
          component:() =>import('../views/policeCheck/CheckPoliceStep5.vue'),
        },
        {
          path: 'check-police-step6/:id/:shId/:projectId/:parentId/:customerId/:checkId/:mode',
          name: 'checkPoliceStep6CU',
          component:() =>import('../views/policeCheck/CheckPoliceStep6.vue'),
        },







        // {
        //   path: 'police-check-submitted-message',
        //   name: 'policeCheckSubmittedMessageCU',
        //   component:() => import('../views/policeCheck/PoliceCheckSubmitedMessage.vue'),
        // },

        {
          path: 'users',
          name: 'usersAdmin',
          component:() => import('../views/admin/UsersList.vue'),
        },
        {
          path: 'profile',
          name: 'profileAdmin',
          component:() => import('../views/admin/Profile.vue'),
        },
        {
          path: 'password-change',
          name: 'passwordChangeAdmin',
          component:() => import('../views/admin/PasswordChange.vue'),
        },
    ],
    beforeEnter(to, from, next){
      if(!auth.isLoggedIn()){
          next('/login');
      }else{
        const userObj = auth.getUserFromToken();
        const  roles  = [3,8, 9];

        if (roles.includes(Number(userObj.role_id))) {
          next()
        }else{
          next('/login');
        }
      }
    }
  },


  {
    path:'/partner',
    name:'partner',
    component:homePartner,
    children:[
        {
            path: '',
            name: 'dashboardPartner',
            component:() => import('../views/partner/Dashboard.vue'),
        },
        {
          path: 'police-check-list',
          name: 'policeCheckListAdmin',
          component:() => import('../views/partner/PoliceCheckList.vue'),
        },
        {
          path: 'customer-list',
          name: 'customerListAdmin',
          component:() => import('../views/partner/CustomerList.vue'),
        },

        // {
        //   path: 'police-check-steps-new/:user_id',
        //   name: 'PoliceCheckNewPartner',
        //   component:() => import('../views/policeCheck/PoliceCheckStepsNew.vue'),
        // },
        {
          path: 'police-check-steps-edit/:userId/:id',
          name: 'PoliceCheckStepsEditPartner',
          component:() => import('../views/policeCheck/PoliceCheckStepsEdit.vue'),
        },
        {
          path: 'police-check-submitted-message',
          name: 'policeCheckSubmittedMessagePartner',
          component:() => import('../views/policeCheck/PoliceCheckSubmittedByPartnerMessage.vue'),
        },





        {
          path: 'police-check-steps-new/:user_id',
          name: 'PoliceCheckNewPartner',
          component:() => import('../views/policeCheck/PoliceCheckStepsNew.vue'),
        },


        // {
        //   path: 'check-police-step1-cu/:id/:shId/:projectId/:parentId/:customerId/:checkId/:mode',
        //   name: 'checkPoliceStep1CU',
        //   component:() =>import('../views/policeCheck/CheckPoliceStep1CU.vue'),
        // },
        {
          path: 'check-police-step2/:id/:shId/:projectId/:parentId/:customerId/:checkId/:mode',
          name: 'checkPoliceStep2CU',
          component:() =>import('../views/policeCheck/CheckPoliceStep2.vue'),
        },
        {
          path: 'check-police-step3/:id/:shId/:projectId/:parentId/:customerId/:checkId/:mode',
          name: 'checkPoliceStep3CU',
          component:() =>import('../views/policeCheck/CheckPoliceStep3.vue'),
        },

        {
          path: 'check-police-step4/:id/:shId/:projectId/:parentId/:customerId/:checkId/:mode',
          name: 'checkPoliceStep4CU',
          component:() =>import('../views/policeCheck/CheckPoliceStep4.vue'),
        },
        {
          path: 'check-police-step5/:id/:shId/:projectId/:parentId/:customerId/:checkId/:mode',
          name: 'checkPoliceStep5CU',
          component:() =>import('../views/policeCheck/CheckPoliceStep5.vue'),
        },
        {
          path: 'check-police-step6/:id/:shId/:projectId/:parentId/:customerId/:checkId/:mode',
          name: 'checkPoliceStep6CU',
          component:() =>import('../views/policeCheck/CheckPoliceStep6.vue'),
        },

        // {
        //   path: 'police-check-submitted-message',
        //   name: 'policeCheckSubmittedMessageCU',
        //   component:() => import('../views/policeCheck/PoliceCheckSubmitedMessage.vue'),
        // },





        {

          path: 'user-list',
          name: 'userListPartner',
          component:() => import('../views/partner/UserList.vue'),
        }
        // {
        //   path: 'profile',
        //   name: 'profileAdmin',
        //   component:() => import('../views/admin/Profile.vue'),
        // },
        // {
        //   path: 'password-change',
        //   name: 'passwordChangeAdmin',
        //   component:() => import('../views/admin/PasswordChange.vue'),
        // },
    ],

    beforeEnter(to, from, next){
      if(!auth.isLoggedIn()){
          next('/login');
      }else{

        const userObj = auth.getUserFromToken();
        const  roles  = [6,7];
        if (roles.includes(Number(userObj.role_id))) {
          next()
        }else{
          next('/login');
        }
      }
    },
  },

  // Common
  {
    path:'/common',
    name:'common',
    component:homeCO,
    children:[
        {
          path: 'police-check-iu-direct-new/:userId',
          name: 'policeCheckIUDirectNew',
          component:() => import('../views/common/PoliceCheckStepsIUDirectNew.vue')
        },
        {
          path: 'police-check-iu-direct-edit/:userId/:id',
          name: 'policeCheckIUDirectEdit',
          component:() => import('../views/common/PoliceCheckStepsIUDirectEdit.vue'),
        },
        // {
        //   path: 'police-check-iu-direct-submitted-message',
        //   name: 'policeCheckIUDirectSubmittedMessage',
        //   component:() => import('../views/policeCheck/PoliceCheckSubmitedMessage.vue'),
        // },

    ],
  },

  // Login
  {
    path: '/',
    name: 'login',
    component:() => import('../views/authentication/Login.vue'),
  },
  // Login
  {
    path: '/login',
    name: 'login',
    component:() => import('../views/authentication/Login.vue'),
  },
   // Select Register category
  {
    path: '/select-register-category',
    name: 'selectRegisterCategory',
    component:() => import('../views/authentication/SelectRegisterCategory.vue'),
  },
  {
    path: '/select-register-individual',
    name: 'selectRegisterIndividual',
    component:() => import('../views/authentication/SelectRegisterIndividual.vue'),
  },
  {
    path: '/select-register-corporate',
    name: 'selectRegisterCorporate',
    component:() => import('../views/authentication/SelectRegisterCorporate.vue'),
  },

  {
    path: '/welcome-message',
    name: 'welcomeMessage',
    component:() => import('../views/authentication/WelcomeMessage.vue'),
  },
  {
    path: '/welcome-message-cm',
    name: 'welcomeMessageCM',
    component:() => import('../views/authentication/WelcomeMessageCM.vue'),
  },



  // Forget password
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component:() => import('../views/authentication/ForgotPassword.vue'),
  },
  // Forget password welcome message
  {
      path: '/forgot-password-welcome-message',
      name: 'forgotPasswordWelcomeMessage',
      component:() => import('../views/authentication/ForgotPasswordWelcomMessage.vue'),
  },
  // Forget password send with token or set password
  {
      path: '/reset-password/:token',
      name: 'resetPassword',
      component:() => import('../views/authentication/ResetPassword.vue'),
  },
  {
    path: '/verify-account/:code',
    name: 'verifyAccount',
    component:() => import('../views/authentication/VerifyAccount.vue'),
  },

  // Account verification message
  {
    path: '/verification-message',
    name: 'verificationMessage',
    component:() => import('../views/authentication/VerificationMessage.vue'),
  },

  /**
   * MFA code verification
   */
  {
    path: '/verification-mfa',
    name: 'verificationMfa',
    component:() => import('../views/authentication/VerifyMFA.vue'),
  },



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import { http, httpFile } from './http_service';


// Pagination
export function listPaginate(obj){
    return http().get(`/professional-membership/list-paginate?page=${obj.currentPage}&paginate=${obj.paginate}&belongs_to=${obj.belongsTo}&q=${obj.search}&selected_status=${obj.selectedStatus}&from_date=${obj.fromDate}&to_date=${obj.toDate}&sort_direction=${obj.sortDirection}&sort_field=${obj.sortField}`);
}

// Add
export function add(data){
    return http().post(`/professional-membership/add`, data)
}
// Update
export function updateTrans(id, data){
    return http().post(`/professional-membership/update-trans/${id}`, data)
}
// Delete
export function deleteTrans(id){
    return http().delete(`/professional-membership/delete-trans/${id}`)
}

// Update Status (Submit)
export function updateStatus(projectId, data){
    return http().put(`/professional-membership/update-status/${projectId}`, data)
}

// Get Single
export function single(projectId){
    return http().get(`/professional-membership/single/${projectId}`);
}

// Get Single by Id
export function singleById(id){
  return http().get(`/professional-membership/single-by-id/${id}`);
}

// Update user information
export function updateUserInfo(userId, data){
    return http().put(`/professional-membership/update-user-info/${userId}`, data)
}

// Get list dashboard data
export function dashboardDataProject(){
    return http().get(`/professional-membership/dashboard-data-project`);
}

import * as messageService from "../../services/message.service";
import * as userService from '../../services/user.service';


const state = {
    userList:[],
    userById:{},

};

const getters = {
    gettUserList:state => (state.userList),
    gettUserById:state => (state.userById),

};

const actions = {
    async fetchUserList({ commit } ){
        try {
            const response = await userService.userAll();

            commit('setUserAll', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
};

const mutations = {
    setUserList:(state, res) => (
        state.userList =  res
    ),
    setUserById:(state, res) => (
        state.userFromId =  res
    ),

};

export default{
    state,
    getters,
    actions,
    mutations,
}
import axios from 'axios';
import store from '../store/modules/base.module';
import * as auth from '../services/auth.service';

export function http(){
    return axios.create({
        baseURL:store.state.apiURL,
        headers:{
            Authorization:'Bearer '+ auth.getAccessToken(),
            'X-Requested-With': 'XMLHttpRequest', 
            'Content-Type': 'application/json'
        }
    });
}

export function httpServerPath(){
    return axios.create({
        baseURL:store.state.serverPath,
    });
}

export function httpFile(){
    return axios.create({
        baseURL:store.state.apiURL,
        headers:{
            Authorization:'Bearer '+ auth.getAccessToken(),
            'Content-Type':'multipart/form-data'
        }
    });
}
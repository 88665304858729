import * as messageService from "../../services/message.service";
import * as userAdminService from '../../services/userAdmin.service';


const state = {
    usersAdminAll:[],
    userByCategory:[],
    userListPaginate:{},
    userPaginate:{},
    userSingle:{},
    stakeholderSettings:{}
};

const getters = {
    gettUsersAdminAll:state => (state.usersAdminAll),
    getUserByCategory:state => (state.userByCategory),
    getUserListPaginate:state => (state.userListPaginate),
    getUserPaginate:state => (state.userPaginate),
    getUserSingle:state => (state.userSingle),
    getStakeholderSettings:state => (state.stakeholderSettings),
};

const actions = {
    async fetchUsersAdminAll({ commit } ){
        try {
            const response = await userAdminService.all();
            commit('setUsersAdminAll', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },

    // Get all User by Category id
    async fetchUserByCategory({ commit }, categoryId ){
        try {
            const response = await userAdminService.byCategory(categoryId);

            commit('setUserByCategory', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },

    // Get list with pagination
    async fetchUserListPaginate({ commit }, obj){
        try {
            commit('setUserPaginate', obj);

            const response = await userAdminService.listPaginate(obj.currentPage, obj.paginate, obj.roleIds, obj.parentId, obj.search, obj.selectedStatus, obj.fromDate, obj.toDate, obj.sortDirection, obj.sortField);

            commit('setUserListPaginate', response.data);

        } catch (error) {
            console.log('Something wrong - fetchPoliceCheckListPaginate', error);
        }
    },

    // Get user information via user ID
    async fetchUserSingle({ commit}, userId){
        try {
            const response = await userAdminService.single(userId);
            commit('setUserSingle', response.data);
        } catch (error) {
            console.log('Something wrong - fetchUserSingle', error);
        }
    },

     // Get stakeholder settings via parent_id
     async fetchStakeholderSettings({ commit}, parentId){
        try {
            const response = await userAdminService.getStakeholderSettings(parentId);
            commit('setStakeholderSettings', response.data);
        } catch (error) {
            console.log('Something wrong - fetchStakeholderSettings', error);
        }
    },

};

const mutations = {
    setUsersAdminAll:(state, res) => (
        state.usersAdminAll =  res
    ),
    setUserByCategory:(state, res) => (
        state.userByCategory =  res
    ),
    setUserListPaginate:(state, res) => (
        state.userListPaginate =  res
    ),
    setUserPaginate:(state, res) => (
        state.userPaginate =  res
    ),
    setUserSingle:(state, res) => (
        state.userSingle =  res
    ),
    setStakeholderSettings:(state, res) => {
        state.stakeholderSettings =  res
    }
};

export default{
    state,
    getters,
    actions,
    mutations,
}

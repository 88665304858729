import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from "./store";
import moment from 'moment';


import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';

import { BootstrapVue } from 'bootstrap-vue';
Vue.use(BootstrapVue);


// ScrollSpy
import { VBScrollspyPlugin } from 'bootstrap-vue'
Vue.use(VBScrollspyPlugin)


import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

// Vue Signature Pad
// https://www.npmjs.com/package/vue-signature-pad
// http://szimek.github.io/signature_pad/
import VueSignaturePad from 'vue-signature-pad';
Vue.use(VueSignaturePad);


// Admin LTE 3
import 'admin-lte/dist/css/adminlte.min.css';
import 'admin-lte/dist/js/adminlte.min.js';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';



// import 'bootswatch/dist/sandstone/bootstrap.min.css'; 

import Vuex from 'vuex';
Vue.use(Vuex);


import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);


// Vue-multiselect
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css'; 
Vue.component('multiselect', Multiselect);


// Scroll to 
var VueScrollTo = require('vue-scrollto');
// You can also pass in the default options
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})


//Vue-progresbar
import VueProgressBar from 'vue-progressbar';
const options1 = {
    color: '#41b883',
    failedColor: '#ff6a6a',
    thickness: '3px',
    transition: {
      speed: '0.2s',
      opacity: '0.6s',
      termination: 300
    },
    // autoRevert: true,
    // location: 'left',
    // inverse: false
  };
   
  Vue.use(VueProgressBar, options1);



Vue.filter('dateConvertYYYYMMDD', function(value){
  if(value){
    return moment(value).format('YYYY-MM-DD');
  }
});

Vue.filter('dateConvertDDMMYYYY', function(value){
  if(value){
    return moment(value).format('DD-MM-YYYY');
  }
});

Vue.filter('ActiveBoolean', function(value){
    if(value == 1){
        return 'Active';
    }else{
        return 'In-active';
    }
});

Vue.filter('YesOrNoBoolean', function(value){
    if(value == 1){
        return 'Yes';
    }else{
        return 'No';
    }
});
// Get Decimal placess
Vue.filter('with-decimal', function(value) {

    if((value != '') && (value != null) && (value != undefined)){
      let num1 = Number(value);
  
      return num1.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
  
});
Vue.filter('with-YesNo', function(value) {

  if(value == 1) {
    return "Yes"
  }else if(value == 0){
    return "No"
  }else{
    return "N/A"
  }

});

// Vue2Editor
// https://www.vue2editor.com/guide.html#installation
import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

// Vue HTML to Paper
// https://randomcodetips.com/vue-html-to-paper/
import VueHtmlToPaper from 'vue-html-to-paper';
const options3 = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css',
    '../public/print.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}
Vue.use(VueHtmlToPaper, options3);


import './css/style.css';

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");



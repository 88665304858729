import * as messageService from "../../services/message.service";
import * as policeCheckService from '../../services/policeCheck.service';



const state = {
    stepId:1,
    policeCheckType:'',
    policeCheckSingle:{},
    policeCheckList:[],
    policeCheckListPaginate:{},
    policeCheckListPaginateCurrent:{},
    dashboardData:{},
    dashboardComponentDataObj:{},
    policeCheckDashboardData:{},

};

const getters = {
    gettStepId:state => (state.stepId),
    gettPoliceCheckType:state => (state.policeCheckType),
    getPoliceCheckSingle:state => (state.policeCheckSingle),
    gettPoliceCheckList:state => (state.policeCheckList),
    getPoliceCheckListPaginate:state => (state.policeCheckListPaginate),
    getPoliceCheckListPaginateCurrent:state => (state.policeCheckListPaginateCurrent),
    gettDashboardData:state => (state.dashboardData),
    gettDashboardComponentDataObj:state => (state.dashboardComponentDataObj),
    getPoliceCheckDashboardData:state => (state.policeCheckDashboardData),

};

const actions = {
    async fetchStepId({ commit }, id ){
        commit('setStepId', id);
    },

    async fetchPoliceCheckType({ commit }, checkType ){
        commit('setPoliceCheckType', checkType);
    },

    async fetchPoliceCheckSingle({ commit }, policeCheckId ){
        try {
            const response = await policeCheckService.single(policeCheckId);

            commit('setPoliceCheckSingle', response.data);

        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);

            console.log('fetchPoliceCheckSingle', error);
        }


    },

/**
 * Get Single Police check
 * Based on Project Id and Product Id
 *
 * @param {*} obj (Project Id and Product Id)
 * @return Object
 */
    async fetchPoliceCheckSingleByProject({ commit }, obj ){
        try {
            const response = await policeCheckService.singleByProject(obj.projectId, obj.productId);

            commit('setPoliceCheckSingle', response.data.data);

        } catch (error) {

            console.log('fetchPoliceCheckSingleByProject', error);
        }


    },
    async fetchPoliceCheckList({ commit}){
        try {
            const response = await policeCheckService.list();

            commit('setPoliceCheckList', response.data);

        } catch (error) {

            console.log('fetchPoliceCheckList', error);
        }


    },
    // Get list with pagination
    async fetchPoliceCheckListPaginate({ commit}, obj){
        try {
            const response = await policeCheckService.listPaginate(obj);

            commit('setPoliceCheckListPaginateCurrent', obj);

            commit('setPoliceCheckListPaginate', response.data);

        } catch (error) {

            console.log('fetchPoliceCheckListPaginate', error);

        }
    },

    // Get dashboard data
    async fetchDashboardData({ commit}, belongsTo){
        try {
            const response = await policeCheckService.dashboardData(belongsTo);

            commit('setDashboardData', response.data);


            let totalDraftPC = 0;
            let totalNewPC = 0;
            let totalMIRPC = 0;
            let totalWFPRPC = 0;
            let totalCompletedPC = 0;
            let totalCanceledPC = 0;

            const arr = response.data;
            let obj={}

            for (var i = 0, len = arr.length; i < len; i++) {
                // Draft PC
                if(arr[i].status == "1"){
                    totalDraftPC = Number(totalDraftPC) + 1
                }

                // New PC
                if(arr[i].status == "2"){
                    totalNewPC = Number(totalNewPC) + 1
                }

                // MIR PC
                if(arr[i].status == "3"){
                    totalMIRPC = Number(totalMIRPC) + 1
                }

                // WFPR PC
                if(arr[i].status == 4){
                    totalWFPRPC = Number(totalWFPRPC) + 1
                }

                // Completed PC
                if(arr[i].status == 5){
                    totalCompletedPC = Number(totalCompletedPC) + 1
                }

                // Canceled PC
                if(arr[i].status == 6){
                    totalCanceledPC = Number(totalCanceledPC) + 1
                }
            }


            obj=  {
                "totalDraftPC":totalDraftPC,
                "totalNewPC":totalNewPC,
                "totalMIRPC":totalMIRPC,
                "totalWFPRPC":totalWFPRPC,
                "totalCompletedPC":totalCompletedPC,
                "totalCanceledPC":totalCanceledPC
            };



            commit('setDashboardComponentDataObj', obj)

        } catch (error) {
            console.log('fetchDashboardData', error);
        }
    },


    // Get dashboard data
    async fetchPoliceCheckDashboardData({ commit}){
        try {
            const response = await policeCheckService.dashboardDataProject();

            let totalDraft = 0;
            let totalNew = 0;
            let totalMIR = 0;
            let totalWFR = 0;
            let totalCompleted = 0;
            let totalCanceled = 0;

            const arr = response.data;
            let obj={}

            for (var i = 0, len = arr.length; i < len; i++) {
                // Draft
                if(arr[i].status == "1"){
                    totalDraft = Number(totalDraft) + 1
                }

                // New
                if(arr[i].status == "2"){
                    totalNew = Number(totalNew) + 1
                }

                // MIR
                if(arr[i].status == "3"){
                    totalMIR = Number(totalMIR) + 1
                }

                // WFR
                if(arr[i].status == 4){
                    totalWFR = Number(totalWFR) + 1
                }

                // Completed
                if(arr[i].status == 5){
                    totalCompleted = Number(totalCompleted) + 1
                }

                // Canceled
                if(arr[i].status == 6){
                    totalCanceled = Number(totalCanceled) + 1
                }
            }


            obj=  {
                "totalDraft":totalDraft,
                "totalNew":totalNew,
                "totalMIR":totalMIR,
                "totalWFR":totalWFR,
                "totalCompleted":totalCompleted,
                "totalCanceled":totalCanceled,
                "total": Number(totalNew) + Number(totalMIR) + Number(totalWFR) + Number(totalCompleted)
            };



            commit('setPoliceCheckDashboardData', obj)

        } catch (error) {
            console.log('fetchPoliceCheckDashboardData', error);

        }
    },
};



const mutations = {
    setStepId:(state, res) => (
        state.stepId =  res
    ),
    setPoliceCheckType:(state, res) => (
        state.policeCheckType =  res
    ),
    setPoliceCheckSingle:(state, res) => (
        state.policeCheckSingle =  res
    ),
    setPoliceCheckList:(state, res) => (
        state.policeCheckList =  res
    ),
    setPoliceCheckListPaginate:(state, res) => (
        state.policeCheckListPaginate =  res
    ),
    setPoliceCheckListPaginateCurrent:(state, res) => (
        state.policeCheckListPaginateCurrent =  res
    ),
    setDashboardData:(state, res) => (
        state.dashboardData =  res
    ),
    setDashboardComponentDataObj:(state, res) => (
        state.dashboardComponentDataObj =  res
    ),
    setPoliceCheckDashboardData:(state, res) => (
        state.policeCheckDashboardData =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}

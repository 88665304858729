import Vue from 'vue'
import Vuex from 'vuex'

import baseModule from "./modules/base.module";
import authModule from "./modules/auth.module";
import documentTypeModule from "./modules/documentType.module";
import documentCategoryModule from "./modules/documentCategory.module";
import settingAllModule from "./modules/settingAll.module";
import policeCheckModule from "./modules/policeCheck.module";
import userAdminModule from './modules/userAdmin.module';
import userModule from './modules/user.module';
import userCorporateModule from './modules/userCorporate.module';
import userIndividualModule from './modules/userIndividual.module';
import productModule from './modules/product.module';
import productCategoryModule from './modules/productCategory.module';
import projectModule from './modules/project.module'
import chkDriversOffencesModule from './modules/chkDriversOffences.module'
import chkBankruptcyRecordModule from './modules/chkBankruptcyRecord.module'
import chkRightWorkAustraliaModule from './modules/chkRightWorkAustralia.module'
import chkAcademicQualificationModule from './modules/chkAcademicQualification.module'
import chkProfessionalMembershipModule from './modules/chkProfessionalMembership.module'
import chkEmploymentHistoryModule from './modules/chkEmploymentHistory.module'
import chkReferenceModule from './modules/chkReference.module'
import notesModule from './modules/notes.module'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    baseModule,
    authModule,
    documentTypeModule,
    documentCategoryModule,
    settingAllModule,
    policeCheckModule,
    userModule,
    userAdminModule,
    userCorporateModule,
    userIndividualModule,
    productModule,
    productCategoryModule,
    projectModule,
    chkDriversOffencesModule,
    chkBankruptcyRecordModule,
    chkRightWorkAustraliaModule,
    chkAcademicQualificationModule,
    chkProfessionalMembershipModule,
    chkEmploymentHistoryModule,
    chkReferenceModule,
    notesModule,
  }
})


import * as academicQualificationService from '../../services/chkAcademicQualification.service';

const state = {
    academicQualificationListPaginate:{},
    academicQualificationListPaginateCurrent:{},
    academicQualificationSingle:{},
    academicQualificationDashboardData:{},
};

const getters = {
    getAcademicQualificationListPaginate:state => (state.academicQualificationListPaginate),
    getAcademicQualificationListPaginateCurrent:state => (state.academicQualificationListPaginateCurrent),
    getAcademicQualification:state => (state.academicQualificationSingle),
    getAcademicQualificationDashboardData:state => (state.academicQualificationDashboardData),
};

const actions = {
     // Get list with pagination
     async fetchAcademicQualificationListPaginate({ commit}, obj){
        try {
            const response = await academicQualificationService.listPaginate(obj);
            commit('setAcademicQualificationListPaginateCurrent', obj);
            commit('setAcademicQualificationListPaginate', response.data);
        } catch (error) {
            console.log('fetchAcademicQualificationListPaginate', error);
        }
    },

    // Get Single by project_id
    async fetchAcademicQualification({ commit }, projectId){
        try {
            const response = await academicQualificationService.single(projectId);
            commit('setChkAcademicQualification', response.data);
        } catch (error) {
            console.log('fetchAcademicQualification - something wrong', error)
        }
    },

    // Get single by Id
    async fetchAcademicQualificationById({ commit }, id){
      try {
          const response = await academicQualificationService.singleById(id);
          commit('setChkAcademicQualification', response.data);
      } catch (error) {
          console.log('fetchAcademicQualificationById - something wrong', error)
      }
    },

    // Get dashboard data
    async fetchAcademicQualificationDashboardData({ commit}){
        try {
            const response = await academicQualificationService.dashboardDataProject();

            let totalDraft = 0;
            let totalNew = 0;
            let totalMIR = 0;
            let totalWFR = 0;
            let totalCompleted = 0;
            let totalCanceled = 0;

            const arr = response.data;
            let obj={}

            for (var i = 0, len = arr.length; i < len; i++) {
                // Draft
                if(arr[i].status == "1"){
                    totalDraft = Number(totalDraft) + 1
                }

                // New
                if(arr[i].status == "2"){
                    totalNew = Number(totalNew) + 1
                }

                // MIR
                if(arr[i].status == "3"){
                    totalMIR = Number(totalMIR) + 1
                }

                // WFR
                if(arr[i].status == 4){
                    totalWFR = Number(totalWFR) + 1
                }

                // Completed
                if(arr[i].status == 5){
                    totalCompleted = Number(totalCompleted) + 1
                }

                // Canceled
                if(arr[i].status == 6){
                    totalCanceled = Number(totalCanceled) + 1
                }
            }

            obj=  {
                "totalDraft":totalDraft,
                "totalNew":totalNew,
                "totalMIR":totalMIR,
                "totalWFR":totalWFR,
                "totalCompleted":totalCompleted,
                "totalCanceled":totalCanceled,
                "total": Number(totalNew) + Number(totalMIR) + Number(totalWFR) + Number(totalCompleted)
            };

            commit('setAcademicQualificationDashboardData', obj)

        } catch (error) {
            console.log('fetchAcademicQualificationDashboardData', error);

        }
    },
};

const mutations = {
    setAcademicQualificationListPaginateCurrent:(state, res) => (
        state.academicQualificationListPaginateCurrent =  res
    ),
    setAcademicQualificationListPaginate:(state, res) => (
        state.academicQualificationListPaginate =  res
    ),
    setChkAcademicQualification:(state, res) => (
        state.academicQualificationSingle =  res
    ),
    setAcademicQualificationDashboardData:(state, res) => (
        state.academicQualificationDashboardData =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}

import { http, httpFile } from './http_service';

// Get all
export function all(){
    return http().get('/police-check/all');
}
// Get list for table
export function list(){
    return http().get('/police-check/list');
}
// Get list with Pagination
export function listPaginate(obj){
    return http().get(`/police-check/list-paginate?page=${obj.currentPage}&paginate=${obj.paginate}&belongs_to=${obj.belongsTo}&q=${obj.search}&selected_status=${obj.selectedStatus}&from_date=${obj.fromDate}&to_date=${obj.toDate}&sort_direction=${obj.sortDirection}&sort_field=${obj.sortField}`);
}
export function listExport(search, selectedStatus,  belongsTo, fromDate, toDate, sortDirection, sortField){
    return http().get(`/police-check/list-export?belongs_to=${belongsTo}&q=${search}&selected_status=${selectedStatus}&from_date=${fromDate}&to_date=${toDate}&sort_direction=${sortDirection}&sort_field=${sortField}`);
}
// Get list dashboard data
export function dashboardData(belongsTo){
    return http().get(`/police-check/dashboard-data?belongs_to=${belongsTo}`);
}

// Get list dashboard data
export function dashboardDataProject(){
    return http().get(`/police-check/dashboard-data-project`);
}

// Get Single
export function single(id){
    return http().get(`/police-check/single/${id}`);
}
/**
 * Get Single by Project id and product id
 * 
 * @param {number} projectId 
 * @param {number} productId 
 * @returns 
 */
export function singleByProject(projectId, productId){
    return http().get(`/police-check/single-by-project/${projectId}/${productId}`);
}
// start new police check
export function add(data){
    return http().post('/police-check/add', data)
}
// Update police check
export function update(id, data){
    return http().put(`/police-check/update/${id}`, data)
}
// Add/Update/Delete Previous names
export function addPreviousNames(id, data){
    return http().post(`/police-check/add-previous-names/${id}`, data)
}
// Add/Update/Delete Previous Address
export function addPreviousAddress(id, data){
    return http().post(`/police-check/add-previous-address/${id}`, data)
}
// To Update Previous Address
export function updateAdditionalInformation(id, data){
    return http().put(`/police-check/update-additional-information/${id}`, data)
}
// Update user information
export function updateUserInfo(userId, data){
    return http().put(`/police-check/update-user-info/${userId}`, data)
}
// Step 5 Select & upload identity documents
export function updateIdentity(pId, data){
    return http().put(`/police-check/update-identity/${pId}`, data)
}
// Step 6 Update Signature
export function signatureUpload(pId, data){
    return http().post(`/police-check/signature-upload/${pId}`, data)
}
// Remove doc and update
export function removeDoc(pId, data){
    return http().put(`/police-check/remove-doc/${pId}`, data)
}
// Update Police check status
export function updateStatus(pId, data){
    return http().put(`/police-check/update-status/${pId}`, data)
}


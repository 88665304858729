import { http, httpFile } from './http_service';
import jwt from 'jsonwebtoken';
import store from '../store/index';

export function register(user){
    // Register Individual user
    return http().post('/auth/register', user);
}

export function registerCM(user){
    // Register Corporate managers
    return http().post('/auth/register-cm', user);
}

export function login(user){
    return http().post('/auth/login', user)
    .then(response => {
        if(response.status === 200){
            setToken(response.data);
        }

        return response.data;
    });
}

export function switchUserLogin(user){
    return http().post('/auth/switch-user-login', user)
    .then(response => {
        if(response.status === 200){
            setToken(response.data);
        }

        return response.data;
    });
}

export function loginWithSocialProvider(provider){
    return http().get(`/auth/${provider}/redirect`)
    .then(response => {
        //if(response.status === 200){
            //setToken(response.data);

            // if(response.data.url){
               //window.location.href = response.data.url;
            // }
       // }

        return response;
    });
}

export function loginWithSocialCallback(provider, code){
    return http().get(`/auth/${provider}/callback`,
        { params:code },
    )
    .then(response => {

        if(response.status === 200){
            setToken(response.data);
        }
        return response.data;
    });
}

function setToken(user){
    const jwtToken =jwt.sign({ user:user }, 'laravelvueloginencript20200725');
    localStorage.setItem('hiro-member-token', jwtToken);

    store.dispatch('fetchAuthenticate');
}

export function isLoggedIn(){
    const token = localStorage.getItem('hiro-member-token');

    return token != null;
}

export function logout(){
    http().get('/auth/logout');
    localStorage.removeItem('hiro-member-token');
}

/**
 * Get all the user information and token scope
 * @returns
 */
export function getAccessTokenAll(){
  const token = localStorage.getItem('hiro-member-token');
      if(!token){
          return null;
      }

  const tokennData = jwt.decode(token);
  return tokennData.user;
}


export function getAccessToken(){
    const token = localStorage.getItem('hiro-member-token');
        if(!token){
            return null;
        }

    const tokennData = jwt.decode(token);
    return tokennData.user.access_token;
}

export function getUserFromToken(){
    const token = localStorage.getItem('hiro-member-token');
        if(!token){
            return null;
        }

    const tokennData = jwt.decode(token);
    return tokennData.user.user;
}


export function forgotPassword(user){
    return http().post('/auth/forgot-password', user);
}

export function resetPassword(data){
    return http().post('/auth/reset-password', data);
}

export function authDelete(id){
    return http().delete(`/auth/auth/delete/${id}`);
}

export function changePassword(userData){
    return http().post('/auth/change-password', userData);
}

export function profileUpdate(userData){
    return http().post('/auth/profile-update', userData);
}
export function accountVerification(code){
    return http().post(`/auth/account-verification/${code}`);
}

// User information form ID
export function userFromId(userId){
    return http().get(`/auth/user-from-id/${userId}`);
}

// Resend Account activation link
export function resendActivationLink(userId){
    return http().post(`/auth/resend-account-activation-link/${userId}`);
}

/**
 * Twillio MFA send code
 */
export function verifyCodeMfa(userData){
  return http().post(`/auth/verify-code-mfa`, userData);
}

export function resendCodeMfa(){
  return http().get(`/auth/resend-code-mfa`);
}




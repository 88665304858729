<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <a href="javascript:void(0)" class="brand-link text-center">
      <img
        src="../../assets/img/logo.png"
        alt="Vehile solutions logo"
        class="brand-image"
      />
    </a>

    <div class="sidebar">
      <div class="user-panel pt-3 pb-3 d-flex">
        <div class="image text-info fs-2500 align-self-center">
          <i class="fas fa-user-circle"></i>
        </div>
        <div class="info align-self-center">
          <a href="javascript:void(0)" class="d-block">{{
            userObj.first_name
          }}</a>
        </div>
      </div>

      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <li class="nav-item">
            <router-link to="/corporate-manager" class="nav-link">
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>Dashboard</p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/corporate-manager/project-list" class="nav-link">
              <i class="nav-icon fas fa-project-diagram"></i>
              <p>Projects</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/corporate-manager/customer-list" class="nav-link">
              <i class="nav-icon fas fa-user-friends"></i>
              <p>Applicants</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="userObj.role_id == 4">
            <router-link to="/corporate-manager/user-list" class="nav-link">
              <i class="nav-icon fas fa-users-cog"></i>
              <p>Users</p>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as authService from "../../services/auth.service";
export default {
  name: "SideBarCorporateManager",
  components: {},
  data() {
    return {
      userObj: {},
    };
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions(["fetchSettingAll"]),
  },
  created() {
    this.userObj = authService.getUserFromToken();
    this.fetchSettingAll();
  },
};
</script>
import * as auth from '../../services/auth.service';
import * as messageService from "../../services/message.service";

const state = {
    isLoggrdIn:null,
    profile:{},
    userFromId:{},


};

const getters = {
    getUserProfile:state => (state.profile),
    getUserFromId:state => (state.userFromId),
};

const actions = {
    fetchAuthenticate({commit}){
        let userInfo='';

        const isLogged = auth.isLoggedIn();

        if(isLogged){
             userInfo = auth.getUserFromToken();
        }else{
            userInfo = {};
        }

        commit('authanticate', userInfo);

    },

    async fetchUserFromId({ commit }, userId){
        try {
            const response = await auth.userFromId(userId);
            commit('setUserFromId', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);

            console.log('Something wrong - fetchUserFromId', error)
        }
    },
};

const mutations = {
    authanticate:(state, res) => (
        state.profile =  res
    ),
    setUserFromId:(state, res) => (
        state.userFromId =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}

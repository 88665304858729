import { http, httpFile } from './http_service';

// Make a new project
export function add(data){
    return http().post('/project/add', data)
}

// Get list with pagination
export function list(){
    return http().get(`/project/list`);
}

// Get list with Pagination
export function listPaginate(obj){
  return http().get(`/project/list-paginate?page=${obj.currentPage}&paginate=${obj.paginate}&cm_id=${obj.cm_id}&q=${obj.search}&selected_status=${obj.selectedStatus}&fromDate=${obj.fromDate}&toDate=${obj.toDate}&sort_direction=${obj.sortDirection}&sort_field=${obj.sortField}`);
}

// Get project by user
export function projectsByUser(userId){
    return httpFile().get(`/project/projects-by-user/${userId}`)
}

// Get single project
export function single(projectId){
    return httpFile().get(`/project/single/${projectId}`)
}

export function listExport(q, fromDate, toDate, userType){
  return http().get(`/project/list-export?q=${q}&from_date=${fromDate}&to_date=${toDate}&userType=${userType}`);
}





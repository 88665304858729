import { http, httpFile } from './http_service';

// Pagination
export function listPaginate(obj){
    return http().get(`/employment-history/list-paginate?page=${obj.currentPage}&paginate=${obj.paginate}&belongs_to=${obj.belongsTo}&q=${obj.search}&selected_status=${obj.selectedStatus}&from_date=${obj.fromDate}&to_date=${obj.toDate}&sort_direction=${obj.sortDirection}&sort_field=${obj.sortField}`);
}

export function listExport(search, selectedStatus,  belongsTo, fromDate, toDate, sortDirection, sortField){
    return http().get(`/employment-history/list-export?belongs_to=${belongsTo}&q=${search}&selected_status=${selectedStatus}&from_date=${fromDate}&to_date=${toDate}&sort_direction=${sortDirection}&sort_field=${sortField}`);
}

// Add
export function add(data){
    return http().post(`/employment-history/add`, data)
}
// Update
export function updateSingle(id, data){
    return http().post(`/employment-history/update-single/${id}`, data)
}
// Update Status
export function updateStatus(projectId, data){
    return http().put(`/employment-history/update-status/${projectId}`, data)
}
// Delete
export function deleteSingle(id){
    return http().delete(`/employment-history/delete-single/${id}`)
}
// Remove file
export function removeFile(id){
    return http().post(`/employment-history/remove-file/${id}`)
}
// Get Single
export function single(projectId){
    return http().get(`/employment-history/single/${projectId}`);
}

// Get Single by Id
export function singleById(id){
  return http().get(`/employment-history/single-by-id/${id}`);
}

// Update user information
export function updateUserInfo(userId, data){
    return http().put(`/employment-history/update-user-info/${userId}`, data)
}

// Get list dashboard data
export function dashboardDataProject(){
    return http().get(`/employment-history/dashboard-data-project`);
}


import * as employmentHistoryService from '../../services/chkEmploymentHistory.service';

const state = {
    employmentHistoryListPaginate:{},
    employmentHistoryListPaginateCurrent:{},
    employmentHistorySingle:{},
    employmentHistoryDashboardData:{},
};

const getters = {
    getEmploymentHistoryListPaginate:state => (state.employmentHistoryListPaginate),
    getEmploymentHistoryListPaginateCurrent:state => (state.employmentHistoryListPaginateCurrent),
    getEmploymentHistory:state => (state.employmentHistorySingle),
    getEmploymentHistoryDashboardData:state => (state.employmentHistoryDashboardData)
};

const actions = {
    // Get list with pagination
    async fetchEmploymentHistoryListPaginate({ commit}, obj){
        try {
            const response = await employmentHistoryService.listPaginate(obj);
            commit('setEmploymentHistoryListPaginateCurrent', obj);
            commit('setEmploymentHistoryListPaginate', response.data);
        } catch (error) {
            console.log('fetchEmploymentHistoryListPaginate', error);
        }
    },

    async fetchEmploymentHistory({ commit }, projectId){
        try {
            const response = await employmentHistoryService.single(projectId);
            commit('setEmploymentHistory', response.data);
        } catch (error) {
            console.log('fetchEmploymentHistory - something wrong', error)
        }
    },

    async fetchEmploymentHistoryById({ commit }, id){
      try {
          const response = await employmentHistoryService.singleById(id);
          commit('setEmploymentHistory', response.data);
      } catch (error) {
          console.log('fetchEmploymentHistoryById - something wrong', error)
      }
    },

    // Get dashboard data
    async fetchEmploymentHistoryDashboardData({ commit}){
        try {
            const response = await employmentHistoryService.dashboardDataProject();

            let totalDraft = 0;
            let totalNew = 0;
            let totalMIR = 0;
            let totalWFR = 0;
            let totalCompleted = 0;
            let totalCanceled = 0;

            const arr = response.data;
            let obj={}

            for (var i = 0, len = arr.length; i < len; i++) {
                // Draft
                if(arr[i].status == "1"){
                    totalDraft = Number(totalDraft) + 1
                }

                // New
                if(arr[i].status == "2"){
                    totalNew = Number(totalNew) + 1
                }

                // MIR
                if(arr[i].status == "3"){
                    totalMIR = Number(totalMIR) + 1
                }

                // WFR
                if(arr[i].status == 4){
                    totalWFR = Number(totalWFR) + 1
                }

                // Completed
                if(arr[i].status == 5){
                    totalCompleted = Number(totalCompleted) + 1
                }

                // Canceled
                if(arr[i].status == 6){
                    totalCanceled = Number(totalCanceled) + 1
                }
            }

            obj=  {
                "totalDraft":totalDraft,
                "totalNew":totalNew,
                "totalMIR":totalMIR,
                "totalWFR":totalWFR,
                "totalCompleted":totalCompleted,
                "totalCanceled":totalCanceled,
                "total": Number(totalNew) + Number(totalMIR) + Number(totalWFR) + Number(totalCompleted)
            };

            commit('setEmploymentHistoryDashboardData', obj)

        } catch (error) {
            console.log('fetchEmploymentHistoryDashboardData', error);

        }
    },
};

const mutations = {
    setEmploymentHistoryListPaginateCurrent:(state, res) => (
        state.employmentHistoryListPaginateCurrent =  res
    ),
    setEmploymentHistoryListPaginate:(state, res) => (
        state.employmentHistoryListPaginate =  res
    ),
    setEmploymentHistory:(state, res) => (
        state.employmentHistorySingle =  res
    ),
    setEmploymentHistoryDashboardData:(state, res) => (
        state.employmentHistoryDashboardData =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}

import * as messageService from "../../services/message.service";
import * as userIndividualService from '../../services/userIndividual.service';


const state = {
    userIndividualPoliceChecksAll:{},
    usersIndividualAll:[],
};

const getters = {
    getUserIndividualPoliceChecksAll:state => (state.userIndividualPoliceChecksAll),
    getUsersIndividualAll:state => (state.usersIndividualAll),
};

const actions = {
    async fetchUserIndividualPoliceChecksAll({ commit }, userId){
        try {
            const response = await userIndividualService.policeChecksAll(userId);
            commit('setUserIndividualPoliceChecksAll', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
    async fetchUsersIndividualAll({ commit }){
        try {
            const response = await userIndividualService.all();
            commit('setUsersIndividualAll', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
};

const mutations = {
    setUserIndividualPoliceChecksAll:(state, res) => (
        state.userIndividualPoliceChecksAll =  res
    ),
    setUsersIndividualAll:(state, res) => (
        state.usersIndividualAll =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}


import * as professionalMembershipService from '../../services/chkProfessionalMembership.service';

const state = {
    professionalMembershipListPaginate:{},
    professionalMembershipListPaginateCurrent:{},
    professionalMembershipSingle:{},
    professionalMembershipDashboardData:{},
};

const getters = {
    getProfessionalMembershipListPaginate:state => (state.professionalMembershipListPaginate),
    getProfessionalMembershipListPaginateCurrent:state => (state.professionalMembershipListPaginateCurrent),
    getProfessionalMembership:state => (state.professionalMembershipSingle),
    getProfessionalMembershipDashboardData:state => (state.professionalMembershipDashboardData)
};

const actions = {
    // Get list with pagination
    async fetchProfessionalMembershipListPaginate({ commit}, obj){
        try {
            const response = await professionalMembershipService.listPaginate(obj);
            commit('setProfessionalMembershipListPaginateCurrent', obj);
            commit('setProfessionalMembershipListPaginate', response.data);
        } catch (error) {
            console.log('fetchProfessionalMembershipListPaginate', error);
        }
    },

    async fetchProfessionalMembership({ commit }, projectId){
        try {
            const response = await professionalMembershipService.single(projectId);
            commit('setProfessionalMembership', response.data);
        } catch (error) {
            console.log('fetchProfessionalMembership - something wrong', error)
        }
    },

    async fetchProfessionalMembershipById({ commit }, id){
      try {
          const response = await professionalMembershipService.singleById(id);
          commit('setProfessionalMembership', response.data);
      } catch (error) {
          console.log('fetchProfessionalMembershipById - something wrong', error)
      }
    },

    // Get dashboard data
    async fetchProfessionalMembershipDashboardData({ commit}){
            try {
                const response = await professionalMembershipService.dashboardDataProject();

                let totalDraft = 0;
                let totalNew = 0;
                let totalMIR = 0;
                let totalWFR = 0;
                let totalCompleted = 0;
                let totalCanceled = 0;

                const arr = response.data;
                let obj={}

                for (var i = 0, len = arr.length; i < len; i++) {
                    // Draft
                    if(arr[i].status == "1"){
                        totalDraft = Number(totalDraft) + 1
                    }

                    // New
                    if(arr[i].status == "2"){
                        totalNew = Number(totalNew) + 1
                    }

                    // MIR
                    if(arr[i].status == "3"){
                        totalMIR = Number(totalMIR) + 1
                    }

                    // WFR
                    if(arr[i].status == 4){
                        totalWFR = Number(totalWFR) + 1
                    }

                    // Completed
                    if(arr[i].status == 5){
                        totalCompleted = Number(totalCompleted) + 1
                    }

                    // Canceled
                    if(arr[i].status == 6){
                        totalCanceled = Number(totalCanceled) + 1
                    }
                }


                obj=  {
                    "totalDraft":totalDraft,
                    "totalNew":totalNew,
                    "totalMIR":totalMIR,
                    "totalWFR":totalWFR,
                    "totalCompleted":totalCompleted,
                    "totalCanceled":totalCanceled,
                    "total": Number(totalNew) + Number(totalMIR) + Number(totalWFR) + Number(totalCompleted)
                };

                commit('setProfessionalMembershipDashboardData', obj)

            } catch (error) {
                console.log('fetchProfessionalMembershipDashboardData', error);

            }
    },
};

const mutations = {
    setProfessionalMembershipListPaginateCurrent:(state, res) => (
        state.professionalMembershipListPaginateCurrent =  res
    ),
    setProfessionalMembershipListPaginate:(state, res) => (
        state.professionalMembershipListPaginate =  res
    ),
    setProfessionalMembership:(state, res) => (
        state.professionalMembershipSingle =  res
    ),
    setProfessionalMembershipDashboardData:(state, res) => (
        state.professionalMembershipDashboardData =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}

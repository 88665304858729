<template>
    <main>
        <div class="wrapper">
            <appHeader></appHeader>

            <appSideBar></appSideBar>
            <div class="content-wrapper">
                <vue-progress-bar class="vueProgressBar"></vue-progress-bar>
                <router-view></router-view>
            </div>
            <appFooter></appFooter>

        </div>
    </main>
</template>

<script>
    import appHeader from '../../components/admin/HeaderCom';
    import appSideBar from '../../components/admin/SideBarCom';
    import appFooter from '../../components/admin/FooterCom';

    export default {
        components:{
            appHeader,
            appSideBar,
            appFooter,
        },

        methods:{

        },

        created(){

        },

        
    }
</script>
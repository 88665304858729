import { http, httpFile } from './http_service';

// Get all
export function all(){
    return http().get('/document-category/all');
}
// Get Single
export function single(id){
    return http().get(`/document-category/single/${id}`);
}
// Add one
export function add(data){
    return http().post('/document-category/add', data)
}
// Update
export function update(id, data){
    return http().put(`/document-category/update/${id}`, data)
}

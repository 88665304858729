
import * as bankruptcyRecordService from '../../services/chkBankruptcyRecord.service';

const state = {
    bankruptcyRecordListPaginate:{},
    bankruptcyRecordListPaginateCurrent:{},
    bankruptcyRecordSingle:{},
    bankruptcyRecordDashboardData:{},
};

const getters = {
    getBankruptcyListPaginate:state => (state.bankruptcyRecordListPaginate),
    getBankruptcyListPaginateCurrent:state => (state.bankruptcyRecordListPaginateCurrent),
    getBankruptcyRecord:state => (state.bankruptcyRecordSingle),
    getBankruptcyRecordDashboardData:state => (state.bankruptcyRecordDashboardData),
};

const actions = {
     // Get list with pagination
     async fetchBankruptcyRecordListPaginate({ commit}, obj){
        try {
            const response = await bankruptcyRecordService.listPaginate(obj);
            commit('setBankruptcyRecordListPaginateCurrent', obj);
            commit('setBankruptcyRecordListPaginate', response.data);
        } catch (error) {
            console.log('fetchBankruptcyRecordListPaginate', error);
        }
    },

    async fetchBankruptcyRecord({ commit }, projectId){
        try {
            const response = await bankruptcyRecordService.single(projectId);
            commit('setBankruptcyRecord', response.data);
        } catch (error) {
            console.log('fetchBankruptcyRecord - something wrong', error)
        }
    },

    async fetchBankruptcyRecordById({ commit }, id){
      try {
          const response = await bankruptcyRecordService.singleById(id);
          commit('setBankruptcyRecord', response.data);
      } catch (error) {
          console.log('fetchBankruptcyRecordById - something wrong', error)
      }
  },

    // Get dashboard data
    async fetchBankruptcyRecordDashboardData({ commit}){
        try {
            const response = await bankruptcyRecordService.dashboardDataProject();

            let totalDraft = 0;
            let totalNew = 0;
            let totalMIR = 0;
            let totalWFR = 0;
            let totalCompleted = 0;
            let totalCanceled = 0;

            const arr = response.data;
            let obj={}

            for (var i = 0, len = arr.length; i < len; i++) {
                // Draft
                if(arr[i].status == "1"){
                    totalDraft = Number(totalDraft) + 1
                }

                // New
                if(arr[i].status == "2"){
                    totalNew = Number(totalNew) + 1
                }

                // MIR
                if(arr[i].status == "3"){
                    totalMIR = Number(totalMIR) + 1
                }

                // WFR
                if(arr[i].status == 4){
                    totalWFR = Number(totalWFR) + 1
                }

                // Completed
                if(arr[i].status == 5){
                    totalCompleted = Number(totalCompleted) + 1
                }

                // Canceled
                if(arr[i].status == 6){
                    totalCanceled = Number(totalCanceled) + 1
                }
            }

            obj=  {
                "totalDraft":totalDraft,
                "totalNew":totalNew,
                "totalMIR":totalMIR,
                "totalWFR":totalWFR,
                "totalCompleted":totalCompleted,
                "totalCanceled":totalCanceled,
                "total": Number(totalNew) + Number(totalMIR) + Number(totalWFR) + Number(totalCompleted)
            };

            commit('setBankruptcyRecordDashboardData', obj)

        } catch (error) {
            console.log('fetchBankruptcyRecordDashboardData', error);

        }
    },
};

const mutations = {
    setBankruptcyRecordListPaginateCurrent:(state, res) => (
        state.bankruptcyRecordListPaginateCurrent =  res
    ),
    setBankruptcyRecordListPaginate:(state, res) => (
        state.bankruptcyRecordListPaginate =  res
    ),
    setBankruptcyRecord:(state, res) => (
        state.bankruptcyRecordSingle =  res
    ),
    setBankruptcyRecordDashboardData:(state, res) => (
        state.bankruptcyRecordDashboardData =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}

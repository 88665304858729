import { http, httpFile } from './http_service';

// Pagination
export function listPaginate(obj){
    return http().get(`/drivers-offences/list-paginate?page=${obj.currentPage}&paginate=${obj.paginate}&belongs_to=${obj.belongsTo}&q=${obj.search}&selected_status=${obj.selectedStatus}&from_date=${obj.fromDate}&to_date=${obj.toDate}&sort_direction=${obj.sortDirection}&sort_field=${obj.sortField}`);
}

// Add or Update
export function addOrUpdate(projectId, data){
    return http().post(`/drivers-offences/add-or-update/${projectId}`, data)
}

// Get Single
export function single(projectId){
    return http().get(`/drivers-offences/single/${projectId}`);
}

// Get Single by Id
export function singleById(id){
  return http().get(`/drivers-offences/single-by-id/${id}`);
}

// Update user information
export function updateUserInfo(userId, data){
    return http().put(`/drivers-offences/update-user-info/${userId}`, data)
}

// Remove File ExtCertificate
export function removeFileExtCertificate(id){
    return http().get(`/drivers-offences/remove-file-ext_certificate/${id}`)
}

// Update Status
export function updateStatus(projectId, data){
    return http().put(`/drivers-offences/update-status/${projectId}`, data)
}

// Get list dashboard data
export function dashboardDataProject(){
    return http().get(`/drivers-offences/dashboard-data-project`);
}

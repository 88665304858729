import * as messageService from "../../services/message.service";
import * as countryService from '../../services/country.service';
import * as documentTypeService from '../../services/documentType.service';



const state = {
    settingAll:{},
};

const getters = {
    getSettingAll:state => (state.settingAll),
};

const actions = {
    async fetchSettingAll({ commit } ){
        try {
            const resCountry = await countryService.all();
            const resDocumentTypeDropdown = await documentTypeService.dropdown();
            const resDocumentTypeDropdown1 = await documentTypeService.dropdownCommencement();
            const resDocumentTypeDropdown2 = await documentTypeService.dropdownPrimary();
            const resDocumentTypeDropdown3 = await documentTypeService.dropdownSecondary();

            let allSetting = {
                "country":resCountry.data,
                "documentTypeDropdown":resDocumentTypeDropdown.data,
                "documentTypeDropdown1":resDocumentTypeDropdown1.data,
                "documentTypeDropdown2":resDocumentTypeDropdown2.data,
                "documentTypeDropdown3":resDocumentTypeDropdown3.data,
            }
            commit('setSettingAll', allSetting);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    }
};

const mutations = {
    setSettingAll:(state, res) => (
        state.settingAll =  res
    )
};

export default{
    state,
    getters,
    actions,
    mutations,
}

import * as userCorporateService from '../../services/userCorporate.service';


const state = {
    userCorporateListPaginate:{},
    userCorporatePaginate:{},
};

const getters = {
    getUserCorporateListPaginate:state => (state.userCorporateListPaginate), 
    getUserCorporatePaginate:state => (state.userCorporatePaginate), 
};

const actions = {
    // Get list with pagination
    async fetchUserCorporateListPaginate({ commit}, obj){
        try {
            commit('setUserCorporatePaginate', obj);

            const response = await userCorporateService.listPaginate(obj.currentPage, obj.paginate, obj.roleIds, obj.parentId, obj.search, obj.selectedStatus, obj.fromDate, obj.toDate, obj.sortDirection, obj.sortField);

            commit('setUserCorporateListPaginate', response.data);

        } catch (error) {
            console.log('Something wrong - fetchUserCorporateListPaginate', error);
        }
    },
};

const mutations = {
    setUserCorporateListPaginate:(state, res) => (
        state.userCorporateListPaginate =  res
    ),
    setUserCorporatePaginate:(state, res) => (
        state.userCorporatePaginate =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}
<template>
    <main>
        <div class="wrapper">
            <appHeader></appHeader>
            <div class="mb-5">
                <vue-progress-bar class="vueProgressBar"></vue-progress-bar>
                <router-view></router-view>
            </div>
            <appFooter></appFooter>
        </div>
        
    </main>
</template>

<script>
    import appHeader from '../../components/common/HeaderCom.vue';
    import appFooter from '../../components/common/FooterCom';

    export default {
        components:{
            appHeader,
            appFooter,
        },

        methods:{

        },

        created(){

        },

        
    }
</script>
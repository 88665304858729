import { http, httpFile } from './http_service';


export function userAll(){
    return http().get('/user');
}
export function userAdd(data){
    return httpFile().post('/user', data)
}
export function userUpdate(id, data){
    return httpFile().post(`/user/${id}`, data)
}

export function userList(){
    return http().get('/user-list');
}

export function deleteUser(id){
    return http().delete(`/user/${id}`);
}



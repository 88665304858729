import { http, httpFile } from './http_service';

// Get all
export function all(){
    return http().get('/document-type/all');
}
// Get all active
export function allActive(){
    return http().get('/document-type/active');
}
// Get Single
export function single(id){
    return http().get(`/document-type/single/${id}`);
}
// Add one
export function add(data){
    return http().post('/document-type/add', data)
}
// Update
export function update(id, data){
    return http().put(`/document-type/update/${id}`, data)
}
// document-type-dropdown
export function dropdown(){
    return http().get(`/document-type/dropdown`)
}
// document-type-dropdownCommencement
export function dropdownCommencement(){
    return http().get(`/document-type/dropdown-commencement`)
}
// document-type-dropdownPrimary
export function dropdownPrimary(){
    return http().get(`/document-type/dropdown-primary`)
}
// document-type-dropdownSecondary
export function dropdownSecondary(){
    return http().get(`/document-type/dropdown-secondary`)
}

<template>
  <footer class="main-footer">
    <div class="float-right d-none d-sm-inline">
      Developed by: <a href="https://samath.com.au/">Samath IT Solutions</a>.
    </div>
    <strong
      >&copy; {{ fullYear }}
      <a href="https://cvscreen.com.au/">CV Screen</a>.</strong
    >
    All rights reserved.
  </footer>
</template>
<script>
export default {
  name: "AdminFooter",
  computed: {
    fullYear() {
      const d = new Date();
      return d.getFullYear();
    },
  },
};
</script>

import * as messageService from "../../services/message.service";
import * as notesService from "../../services/notes.service";
import * as notesPublicService from "../../services/notesPublic.service"



const state = {
    notesList:{},
    notesPublicList:{},
};

const getters = {
    getNotesList:state => (state.notesList),
    getNotesPublicList:state => (state.notesPublicList),
};

const actions = {
    async fetchNotesList({ commit}, noteId){
        try {
            const response = await notesService.list(noteId);
            commit('setNotesList', response.data.data);

        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            console.log('fetchNotesList', error);

            messageService.fnSweetAlertDisplay(obj);
        }


    },
    async fetchNotesPublicList({ commit}, noteId){
        try {
            const response = await notesPublicService.list(noteId);
            commit('setNotesPublicList', response.data.data);

        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            console.log('fetchNotesPublicList', error);

            messageService.fnSweetAlertDisplay(obj);
        }


    },
};

const mutations = {
    setNotesList:(state, res) => (
        state.notesList =  res
    ),
    setNotesPublicList:(state, res) => (
        state.notesPublicList =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}

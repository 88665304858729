
import * as driversOffencesService from '../../services/chkDriversOffences.service';

const state = {
    driversOffencesListPaginate:{},
    driversOffencesListPaginateCurrent:{},
    driversOffencesSingle:{},
    driversOffencesDashboardData:{},
};

const getters = {
    getDriversOffencesListPaginate:state => (state.driversOffencesListPaginate),
    getDriversOffencesListPaginateCurrent:state => (state.driversOffencesListPaginateCurrent),
    getDriversOffences:state => (state.driversOffencesSingle),
    getDriversOffencesDashboardData:state => (state.driversOffencesDashboardData),
};

const actions = {
    // Get list with pagination
    async fetchDriversOffencesListPaginate({ commit}, obj){
        try {
            const response = await driversOffencesService.listPaginate(obj);
            commit('setDriversOffencesListPaginateCurrent', obj);
            commit('setDriversOffencesListPaginate', response.data);
        } catch (error) {
            console.log('fetchDriversOffencesListPaginate', error);
        }
    },

    async fetchDriversOffences({ commit }, projectId){
        try {
            const response = await driversOffencesService.single(projectId);
            commit('setChkDriversOffences', response.data);
        } catch (error) {
            console.log('fetchDriversOffences - something wrong', error)
        }
    },

    async fetchDriversOffencesById({ commit }, id){
      try {
          const response = await driversOffencesService.singleById(id);
          commit('setChkDriversOffences', response.data);
      } catch (error) {
          console.log('fetchDriversOffencesById - something wrong', error)
      }
    },

    // Get dashboard data
    async fetchDriversOffencesDashboardData({ commit}){
        try {
            const response = await driversOffencesService.dashboardDataProject();

            let totalDraft = 0;
            let totalNew = 0;
            let totalMIR = 0;
            let totalWFR = 0;
            let totalCompleted = 0;
            let totalCanceled = 0;

            const arr = response.data;
            let obj={}

            for (var i = 0, len = arr.length; i < len; i++) {
                // Draft
                if(arr[i].status == "1"){
                    totalDraft = Number(totalDraft) + 1
                }

                // New
                if(arr[i].status == "2"){
                    totalNew = Number(totalNew) + 1
                }

                // MIR
                if(arr[i].status == "3"){
                    totalMIR = Number(totalMIR) + 1
                }

                // WFR
                if(arr[i].status == 4){
                    totalWFR = Number(totalWFR) + 1
                }

                // Completed
                if(arr[i].status == 5){
                    totalCompleted = Number(totalCompleted) + 1
                }

                // Canceled
                if(arr[i].status == 6){
                    totalCanceled = Number(totalCanceled) + 1
                }
            }

            obj=  {
                "totalDraft":totalDraft,
                "totalNew":totalNew,
                "totalMIR":totalMIR,
                "totalWFR":totalWFR,
                "totalCompleted":totalCompleted,
                "totalCanceled":totalCanceled,
                "total": Number(totalNew) + Number(totalMIR) + Number(totalWFR) + Number(totalCompleted)
            };

            commit('setDriversOffencesDashboardData', obj)

        } catch (error) {
            console.log('fetchDriversOffencesDashboardData', error);

        }
    },
};

const mutations = {
    setDriversOffencesListPaginateCurrent:(state, res) => (
        state.driversOffencesListPaginateCurrent =  res
    ),
    setDriversOffencesListPaginate:(state, res) => (
        state.driversOffencesListPaginate =  res
    ),
    setChkDriversOffences:(state, res) => (
        state.driversOffencesSingle =  res
    ),
    setDriversOffencesDashboardData:(state, res) => (
        state.driversOffencesDashboardData =  res
    )
};

export default{
    state,
    getters,
    actions,
    mutations,
}

import { http, httpFile } from './http_service';

// Pagination
export function listPaginate(obj){
    return http().get(`/reference/list-paginate?page=${obj.currentPage}&paginate=${obj.paginate}&belongs_to=${obj.belongsTo}&q=${obj.search}&selected_status=${obj.selectedStatus}&from_date=${obj.fromDate}&to_date=${obj.toDate}&sort_direction=${obj.sortDirection}&sort_field=${obj.sortField}`);
}
// Add
export function add(data){
    return http().post(`/reference/add`, data)
}
// Add
export function addTrans(data){
    return http().post(`/reference/add-trans`, data)
}
// Update
export function updateTrans(id, data){
    return http().post(`/reference/update-trans/${id}`, data)
}
// Delete
export function deleteTrans(id){
    return http().delete(`/reference/delete-trans/${id}`)
}
// Update Status (Submit)
export function updateStatus(projectId, productId, data){
    return http().put(`/reference/update-status/${projectId}/${productId}`, data)
}
/**
 * Get Single
 * @param { projectId, productId } data product_id and project_id
 * @returns
 */
export function single(obj){
    return http().get(`/reference/single/${obj.projectId}/${obj.productId}`);
}

// Get Single by Id
export function singleById(id){
  return http().get(`/reference/single-by-id/${id}`);
}

// Update user information
export function updateUserInfo(userId, data){
    return http().put(`/reference/update-user-info/${userId}`, data)
}
// Get list dashboard data
export function dashboardDataProject(){
    return http().get(`/reference/dashboard-data-project`);
}

import { http, httpFile } from './http_service';

// Pagination
export function listPaginate(obj){
    return http().get(`/academic-qualification/list-paginate?page=${obj.currentPage}&paginate=${obj.paginate}&belongs_to=${obj.belongsTo}&q=${obj.search}&selected_status=${obj.selectedStatus}&from_date=${obj.fromDate}&to_date=${obj.toDate}&sort_direction=${obj.sortDirection}&sort_field=${obj.sortField}`);
}

// Add
export function add(data){
    return http().post(`/academic-qualification/add`, data)
}
// Update
export function updateTrans(id, data){
    return http().post(`/academic-qualification/update-trans/${id}`, data)
}
// Delete
export function deleteTrans(id){
    return http().delete(`/academic-qualification/delete-trans/${id}`)
}

// Update Status (Submit)
export function updateStatus(projectId, data){
    return http().put(`/academic-qualification/update-status/${projectId}`, data)
}

// Get Single by Project id
export function single(projectId){
    return http().get(`/academic-qualification/single/${projectId}`);
}

// Get Single by Id
export function singleById(id){
  return http().get(`/academic-qualification/single-by-id/${id}`);
}

// Update user information
export function updateUserInfo(userId, data){
    return http().put(`/academic-qualification/update-user-info/${userId}`, data)
}

// Get list dashboard data
export function dashboardDataProject(){
    return http().get(`/academic-qualification/dashboard-data-project`);
}
